.generic-search-input:focus{
    border: none !important;
}
.genericSearchDiv{
    display: flex;
    width: 100%;
}
.generic-search-button{
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}
.generic-search-input{
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}