p {
    font-size: 16px !important;
}

ListSubheader {
    font-weight: 800 !important;
    color: black;
}

.jodit {
    width: "80%";
    height: "90%";
    margin: "30px";
    font-family  :'SF Pro Display', sans-serif;       
    font-weight : 400;
    font-size: 1.5rem;
    color: var(--primary-text-color);
    overflow: hidden;
    
}

.form-field {
    display: flex;
    column-gap: 30px;
    align-items: center;
    padding: 0 20px;
}

.form-field>label {
    color: black;
    font-size: 16px;
}

.form-field>input {
    outline: none;
    border: none;
    border-bottom: 1px solid #d7d7d7;
    /* margin-bottom: 20px; */
    margin-top: 10px;
    width: 100%;
}
.form-field>input:focus {
    border-bottom: 1px solid #734bd1;
}
.form-field>p{
    font-size: 16px;
}
.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
    height: 50%;

  }
  .editor-class {
    background-color:white;
    padding: 1rem;
   /* border: 1px solid #ccc; */
  }
  .toolbar-class {
    border: 1px solid #ccc;
  }
  .btn{
    margin: auto;
    display: block;
    margin-left: 20px;
  }
  .form-field input{
    font-size: medium;
  }
  
  .wrapper-class{
    font-size: medium;
    color: var(--secondary-text-color);
  }

  .dataResult {
    margin-top: 5px;
    width: 300px;
    height: 200px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    top: auto;
    z-index: 3;
    opacity: 1;
  }
  
  .dataResult::-webkit-scrollbar {
    display: none;
  }
  
  .dataResult .dataItem {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: black;
  }
  
  .dataItem p {
    margin-left: 10px;
  }
  a {
    text-decoration: none;
  }
  
  a:hover {
    background-color: lightgrey;
  }
  .searchInputs {
    /* margin-top: 105px; */
    display: flex;
  }
  .searchInputs input {
    background-color: white;
    border: 0;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: medium;
    height: 30px;
    width: 78vw;
    /* border-bottom: 1px solid lightgray; */
  }
  .email-wrapper{
    border-bottom: 1px solid lightgray;
  }
  .searchInputs .MuiInputBase-formControl{
    border: none !important;
    width: 75vw !important;
  }
  .attachment-btn{
    cursor: pointer;
  }
  .email-attachment-show{
    height: 5rem;
    overflow: hidden;
    width:100%;
    display: flex;
    justify-content: center;
  }
  .btn-emailattachment{
    border: none;
    background: none;
    margin-left: 10px;
  }
  .margin-right{
    margin-right: 1rem;
  }

  .file-wrapper{
    /* height: 30px;
    width: 130px; */
    background-color: #e5e5e5;
    /* margin-top: 10px;
    display: flex;*/
    margin: 5px; 
  }

  .attached-media {
    display: flex;
    justify-content: start;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    max-height: 100px;
    padding: 0 20px;
    color: var(--primary-text-color);
  }
  
  .attached-media .media-tb {
    height: 30px;
    width: 130px;
    background-color: #e5e5e5;
    margin-top: 10px;
    display: flex;
    margin: 5px;
    /* border: 1px solid red; */
  }

  .action-btns {
    z-index: 999;
  }