.errordiv{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    width: 100%;
    height:100vh;
    color:#b5b5b5;
    /* padding-top: 20%; */
    
}
.error h1{
    color: var(--primary-color);
    font-weight: bold;
    
}
.error img{
    
    width: 20%;
    height: 20%;
    
}
/* @media only screen and (max-height: 600px){
    .errordiv {
        padding-top: 0px;
    }
}
@media only screen and (max-width: 100px) {
    .errordiv {
        padding-top: 0px;
    }
} */