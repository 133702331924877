
.MuiPaper-root{
    /* width: 30% !important; */

    /* padding: 0.1rem !important; */
    /* border-radius: 1rem !important; */
    border: none;
    height: unset;
}
.responsive-dialog-title{
    display: flex !important;
}

.custom-modal{
    position:fixed;
    
}

/* .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid rgba(0,0,0,.160784);
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.Modal .modal-body{
    padding: 1rem 2rem 2rem 2rem;
}

@media only screen and (min-width: 1200px)
{
    .form-group--first {
    margin-bottom: 4rem;
}
.modal .modal-content {
    width: 30%;
    padding: 3.5rem;
    border-radius: 2rem;
    border: none;
    height: unset;
}
}
.modal .modal-content {
    width: 30%;
    padding: 3.5rem;
    border-radius: 2rem;
    border: none;
    height: unset;
}
.form-group--first p {
    color: #734bd1;
    font-size: 1.5rem;
    font-weight: 700;
}

.form-group--first div {
    display: inline-flex;
    margin-right: 1.6rem;
    justify-content: center;
    align-items: center;
    padding-left: 0;
}

.custom-control-input:disabled~.custom-control-label {
    color: #595959;
}
.form-group--first div label {
    color: #595959;
    font-weight: 500;
    padding-left: 0.4rem;
}

.form-group--fourth input, .form-group--third input {
    width: 100%;
    border: none;
    box-shadow: 0 0 3px -1px;
   
    height: 2.5rem;
    border-radius: 3rem;
    color: #595959;
    font-weight: 500;
    -webkit-appearance: none;
    background-color: #faf8ff!important;
    outline: none;
}


.form-group--fifth .floating-label, .form-group--fourth .floating-label, .form-group--third .floating-label {
    position: absolute;
    top: -1rem;
    left: 2rem;
    color: #734bd1;
    font-size: 1.1rem;
    font-weight: 700;
    background: #fff;
    padding: 0 0.4rem;
}


.modal {
    display: none;
    position: fixed;
    padding-top: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #000;
    background-color: rgba(0,0,0,.4);
} */



.modal-header{
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem 0rem 1rem;

    border-bottom: 1px solid #00000029;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.d-flex {
    display: flex !important;
}

.font-createcontact {
    font-size: 1.2rem !important;
    line-height: 2.8rem;
    font-weight: bold;
}

.modal-header h1 {
    color: var(--secondary-text-color);
}

.modal-header .close {
   
   
    float: right;
    font-size: 28px;
    font-weight: bold;
  
    border-radius: 0 0 0 90px;
    opacity: 1;
    text-align: right;

    background-color: transparent !important;
    color: var(--secondary-text-color);
    width: 6.5rem;
    cursor: pointer;
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    
}

.modal-body .selecttype-radio-btns{
    position: relative;
    margin-bottom: 1.2rem;
}

.modal-body .selecttype-radio-btns .selectfont {
    font-size: 2rem !important;
    line-height: 2.5rem;
    color: var(--primary-color) !important;
    font-weight: 700;
   
}

#demo-customized-radios{
    font-weight: bold;
    color: var(--primary-color);
}

.css-hjtp1-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 100vw !important;
    
}

p:not(:last-child) {
    margin-bottom: 10px;
}

.inputRounded{
    border-radius: 100vw !important;
 }

 .form-fields-div{
     display: flex;
     flex-direction: column;
     align-items: normal;
     row-gap: 1rem;
 }
 .form-fields-div .create-btn{
    border: 1px solid transparent;
    border-radius: 4rem;
    text-decoration: none;
    background-color: var(--primary-color);
    display: inline-block;
    cursor: pointer;
    font-size: 1rem;
    outline: none;
    position: relative;
    transition: all ease-in-out 0.25s;
    color: #fff;
    
    font-weight: 600;
    margin-bottom: 2rem;
    padding: 0.5rem 1.2rem;
    box-shadow: 0px 0px 15px #00000026;
    white-space: nowrap;
    margin: 0 auto;
}

.font-md{
    color: var(--primary-color);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
}

.asso-prescriber-div{
    display: flex;
    flex-direction: column;
}

.css-19285mc-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: var(--primary-color) !important;
}
.css-19285mc-MuiFormLabel-root-MuiInputLabel-root{
    font-weight: bold !important;
}
.css-hjtp1-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #dee2e6a8 !important;
    border-width: 1px !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border-color: #dee2e6a8 !important;
    border-width: 1px !important;
}

.presListDiv{
    position:absolute;
   height: 5rem;
    overflow-y: scroll;
    width: 90% !important; 
    background: white;
    z-index: 3;
    font-size: 20px;
}


.asso-prescriber-div > div > div{
    width: 100%;
}

#presList {
    max-height: 10rem;
    overflow-y:auto;
    overflow-x: hidden;
}

#presList div{
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    padding: 0.5rem;
    font-weight: 00;
    color: #929292;
}
.title-assocaite_prescribers{
    margin-bottom: 0 !important;
}

.editContacts{
    height: 27rem;
    overflow-y: auto;
}

/* @media only screen and (max-width: 768px) {
    .contact-box{ 
        width: 99% !important;
        height: 99% !important;
    }
} */



.addContactWrapper {
    display: flex;
    width:100% ;
}