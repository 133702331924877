.btn-close{
    padding: 0px !important;
    margin: 8px !important;
    width: 15px;
    height: 10px;
}
.modal-dialog{
    padding-top: 7rem;
}
.modal-lg{
    max-width: 30%;
}
.modal-title{
    font-weight: bold;
    color:black;
}
.modal-header{
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}
.modal-content{
    border-radius: 1rem;
}
.MuiInputLabel-formControl{
    color: var(--primary-color) !important;
    font-weight: bold !important;
}
.rounded-primary-40:hover {
    color: #DFD7F4;
}
.rounded-primary-40 {
    border-radius: 4rem;
    background-color: var(--primary-color);
    color: #fff;
    opacity: 1 !important;
    font-weight: 600;
    margin-bottom: 1rem;
    padding: 1rem 2rem;
    box-shadow: 0px 0px 15px #00000026;
    white-space: nowrap;
}
.submitform{
    display: flex;
    margin-top: 2rem;
}
.MuiInputBase-formControl{
    border-radius: 2rem !important; 
}
/* .form-group--third .floating-label, .form-group--fourth .floating-label, .form-group--fifth .floating-label {
    position: absolute;
top: 20.5rem;
left: 2rem;
color: #734BD1;
font-size: 1rem;
font-weight: 400;
background: #fff;
padding: 0rem 0.4rem;
} */
.form-group--third input, .form-group--fourth input, .form-group--fifth input {
    /* width: 100%;
    margin-top: 2rem;
    border: none;
    box-shadow: 0px 0px 3px -1px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    height: 3.5rem;
    border-radius: 1rem;
    color: #595959;
    font-weight: 500;
    -webkit-appearance: none; */
    /* background-color: #FAF8FF !important; */
    outline: none;
}
.description{
    top:20.3rem;
}
.genericCalDropdown_label{
    color: #734BD1 !important;
    /* background: white; */
    z-index: 1;
    display: block !important;
    transform: translate(14px, -9px) scale(0.75);
    /* position: relative !important; */
    font-weight: bold !important;
}
@media only screen and (max-width: 480px){
    .modal-lg{
        max-width: unset;
    }
    .MuiInputBase-formControl {
        border-radius: 2rem !important;
    }
    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
        border-radius: 1rem !important;
    }
    /* .form-group--third .floating-label, .form-group--fourth .floating-label, .form-group--fifth .floating-label {
        position: absolute;
    top: 20.5rem;
    left: 2rem;
    color: #734BD1;
    font-size: 1rem;
    font-weight: 400;
    background: #fff;
    padding: 0rem 0.4rem;
    } */
    .form-group--third input, .form-group--fourth input, .form-group--fifth input {
        /* width: 100%;
        margin-top: 2rem;
        border: none;
        box-shadow: 0px 0px 3px -1px;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        height: 3.5rem;
        border-radius: 1rem;
        color: #595959;
        font-weight: 500;
        -webkit-appearance: none; */
        /* background-color: #FAF8FF !important; */
        outline: none;
    }
    .genericCalDropdown_label{
        color: #734BD1 !important;
        /* background: white; */
        z-index: 1;
        display: block !important;
        transform: translate(14px, -9px) scale(0.75);
        position: absolute !important;
        font-weight: bold !important;
    }
}
