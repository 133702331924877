
.genericDropdown_select{
     width: 100%;
    border: 1px solid #d3d3d370 !important;
    border-radius: 100vw !important;
   
}
.genericDropdown_select input{
    /* width: 100%;
    border: solid 1px black !important;
    border-radius: 10vw !important; */
    font: inherit;
    color: #000000ba;
    font-size: 1rem;
    letter-spacing: inherit;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.3rem;
    margin: 0;
    display: block;
    min-width: 0;
    width: 100%;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    padding: 16.5px 10px;
    border: 1px solid #d3d3d370;
    border-radius: 100vw;
}

.genericDropdown_label{
    color: var(--primary-color) !important;
    /* background: white; */
    z-index: 1;
    display: block !important;
    transform: translate(14px, -9px) scale(0.75);
    position: absolute !important;
    font-weight: bold !important;
    

}
.genericDropdown_select .MuiSvgIcon-fontSizeMedium{
    color: var(--primary-color) !important;
}

.MuiPaper-root{
    width:unset !important;

    padding: unset!important;
    border-radius: unset !important;
    border: 0.5px solid #8080803b!important;
    height: unset !important;
}


.genericDropdownDiv{
    /* min-width: 20rem; */
    height: 70%;
    /* background: white; */
    border-radius: 100vw !important;
    color: #595959; 
}

.disabledColor{
    background-color: #f7f4ff!important;
    border-radius: 50%;
    color: #828287 !important;
}
