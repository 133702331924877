.custom-wrapper{
    width: 100%;
}

.shadow-none input:focus{
    box-shadow: none;
}

.dropdown-button:hover{
    background: none;
}

.react-tel-input .selected-flag {
    background: transparent !important;
}

.react-tel-input .special-label {
    display: flex !important;
    left: 15px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-size: 12px !important;
    color: var(--primary-color);
    background: transparent !important;
    font-weight: bold;
    z-index: 3 !important;
}
.react-tel-input .selected-flag .flag {
    scale: 1.6 !important;
}

.react-tel-input :disabled {
    background: var(--bg--purple) !important;
    color: var(--primary-text-color) !important; 
    background-color: var(--bg--purple) !important;
}