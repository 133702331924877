 .preview-video-container{
    width: 15rem;
    height: auto;
    /* height: 10rem; */
    background: #e2daf5;
}
.preview-tab-button{
    color: #8b8597;
    text-decoration: none !important;
    padding: 0.5rem;
    /* background-color: #d7d9dc; */
    text-align: center;

}
@media only screen and (max-width:1200px) {
    .preview-tab-mobile-button .preview-video-mobile-container{
        width: 12rem;
        height: auto;
        background: #ffffff !important;
    }
    .preview-tab-mobile-button{
        color: #8b8597 !important;
        text-decoration: none !important;
        padding: 0.5rem;
        /* background-color: #e2daf5; */
        text-align: center;
        margin-bottom: 0.5rem;
    
    }
    }