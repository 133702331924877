p {
    margin: 0;
}

.responsive-dialog-title {
    display: flex !important;
}

.custom-modal {
    position: fixed;

}

.pre_approvetemplate input {
    height: 1rem;
    overflow: hidden;
}

.pre_approvetemplate {
    padding: 3rem 2rem 2rem 2rem;
}

.dyn-templ-error {
    font-size: 13px;
    padding: 0px;
    color: red;
    margin-left: 1rem;
}

/* .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid rgba(0,0,0,.160784);
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.Modal .modal-body{
    padding: 1rem 2rem 2rem 2rem;
}

@media only screen and (min-width: 1200px)
{
    .form-group--first {
    margin-bottom: 4rem;
}
.modal .modal-content {
    width: 30%;
    padding: 3.5rem;
    border-radius: 2rem;
    border: none;
    height: unset;
}
}
.modal .modal-content {
    width: 30%;
    padding: 3.5rem;
    border-radius: 2rem;
    border: none;
    height: unset;
}
.form-group--first p {
    color: #734bd1;
    font-size: 1.5rem;
    font-weight: 700;
}

.form-group--first div {
    display: inline-flex;
    margin-right: 1.6rem;
    justify-content: center;
    align-items: center;
    padding-left: 0;
}

.custom-control-input:disabled~.custom-control-label {
    color: #595959;
}
.form-group--first div label {
    color: #595959;
    font-weight: 500;
    padding-left: 0.4rem;
}

.form-group--fourth input, .form-group--third input {
    width: 100%;
    border: none;
    box-shadow: 0 0 3px -1px;
   
    height: 2.5rem;
    border-radius: 3rem;
    color: #595959;
    font-weight: 500;
    -webkit-appearance: none;
    background-color: #faf8ff!important;
    outline: none;
}


.form-group--fifth .floating-label, .form-group--fourth .floating-label, .form-group--third .floating-label {
    position: absolute;
    top: -1rem;
    left: 2rem;
    color: #734bd1;
    font-size: 1.1rem;
    font-weight: 700;
    background: #fff;
    padding: 0 0.4rem;
}


.modal {
    display: none;
    position: fixed;
    padding-top: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #000;
    background-color: rgba(0,0,0,.4);
} */



.modal-header {
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem 0rem 1rem;

    border-bottom: 1px solid #00000029;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.d-flex {
    display: flex !important;
}

.font-newchat {
    font-size: 1.2rem !important;
    line-height: 2.8rem;
    font-weight: bold;
}

.modal-header h1 {
    color: var(--secondary-text-color);
}

.modal-header .close {


    float: right;
    font-size: 28px;
    font-weight: bold;

    border-radius: 0 0 0 90px;
    opacity: 1;
    text-align: right;

    background-color: transparent !important;
    color: var(--secondary-text-color);
    width: 6.5rem;
    cursor: pointer;
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;

}

.modal-body .selecttype-radio-btns {
    position: relative;
    margin-bottom: 1.2rem;
}

.modal-body .selecttype-radio-btns .selectfont {
    font-size: 2rem !important;
    line-height: 2.5rem;
    color: var(--primary-color) !important;
    font-weight: 700;

}

#demo-customized-radios {
    font-weight: bold;
    color: var(--primary-color);
}

.css-hjtp1-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 100vw !important;

}

p:not(:last-child) {
    margin-bottom: 10px;
}

.inputRounded {
    border-radius: 100vw !important;
}

.form-fields-div {
    display: flex;
    flex-direction: column;
    align-items: normal;
    row-gap: 1rem;
}

.form-fields-div .create-btn {
    border: 1px solid transparent;
    border-radius: 4rem;
    text-decoration: none;
    background-color: rgb(115, 75, 209);
    display: inline-block;
    cursor: pointer;
    font-size: 1rem;
    outline: none;
    position: relative;
    transition: all ease-in-out 0.25s;
    color: #fff;

    font-weight: 600;
    margin-bottom: 2rem;
    padding: 0.5rem 1.2rem;
    box-shadow: 0px 0px 15px #00000026;
    white-space: nowrap;
    margin: 0 auto;
}

.font-md {
    color: var(--primary-color);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
}

.asso-prescriber-div {
    display: flex;
    flex-direction: column;
}

.css-19285mc-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: var(--primary-color) !important;
}

.css-19285mc-MuiFormLabel-root-MuiInputLabel-root {
    font-weight: bold !important;
}

.css-hjtp1-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #dee2e6a8 !important;
    border-width: 1px !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #dee2e6a8 !important;
    border-width: 1px !important;
}

.presListDiv {
    position: absolute;
    height: 5rem;
    overflow-y: scroll;
    width: 90% !important;
    background: white;
    z-index: 3;
    font-size: 20px;
}


.asso-prescriber-div>div>div {
    width: 100%;
}

#presList div {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    padding: 0.5rem;
    font-weight: 00;
    color: #929292;
}

.template_body_form {
    margin-top: 1rem;
    display: none;
}

.template_body_form p {
    font-size: 0.899rem;
}

.template_body_form textarea {
    width: 100%;
    max-height: 30vh;
    height: 110px;
    opacity: 1;
    display: block;
}

.template_assets_form {
    margin-top: 1.3rem;
    position: relative;
    border: 1px solid #dee2e6a8 !important;
}

.template_assets_form ul {
    list-style: none;
    font-size: 1.099rem;
    /* margin: 0 1rem; */
    padding: 1rem;
}

.template_assets_form li {
    position: relative;
    /* padding: 1rem; */
    /* border-color: #dee2e6a8 !important; */
}

.template_assets_form li::before {
    content: "";
    position: absolute;
    top: .5rem;
    left: 0;
    height: 0.7rem;
    width: 0.7rem;
    background: rgba(0, 0, 0, .160784);
    border-radius: 100%;
}

.template_assets_form li a {
    text-decoration: none;
    color: #929292;
    margin-left: 1.2rem;
}

.template_assets_form li a:hover {
    color: var(--primary-color);
}

.template_assets_form label {
    font-size: 0.899rem;
    position: absolute;
    top: -1rem;
    left: 0rem;
    color: var(--primary-color);
    /* font-size: 1.1rem; */
    font-weight: 700;
    background: #fff;
    padding: 0 0.4rem;
}

.newChatModalBody {
    display: flex;
    justify-content: space-between;
}

.width100per {
    width: 100%;
}

/* .new-chat-prescriber .createContactAssociate >h1{
 display: none;
} */
.pre-approve-modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
    width: 28%;
    border-radius: 1rem;
    padding: .5rem;
    background-color: white;
    border: none;
}

/* .pre_approvetemplate .MuiBox-root {
    width: 30%;
    position: relative;
    padding: 1.5rem;
} */
.pre_approvetemplate_body {
    height: calc(50% - 0px);
    /* max-height: 50%; */
    overflow-y: auto;
    overflow-x: hidden;
}

.pre_approvetemplate_head {
    padding: 1rem 0.7rem;
}

.pre_approvetemplate_body {
    overflow-y: auto;
    padding: 0;
    /* height: 50vh; */
}

.template-modal-list {
    height: 2rem;
    padding-bottom: 3.6rem;
}

@media only screen and (max-width: 1200px) {
    .searchHeader {
        background-color: #fff;
        border: 2px solid #dcd8d840;
        padding: 0.5rem;
    }

    .chatListPage .content__header__searchbox {
        width: 95%;
    }
}

.template-modal-list-item {
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, .160784);
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, .101961);
    transition: all .25s ease-in-out;
    box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    margin-bottom: 0.5rem;
}

.template-modal-list-item:last-child {
    margin-bottom: 7rem;
}

.template-modal-body-title>p {
    margin-bottom: 0;
}

.template-modal-body-title b {
    font-size: 1.2rem;
    line-height: inherit;
}

.template-modal-body-content {
    font-size: 1.099rem;
    word-break: break-word;
    white-space: pre-wrap !important;
}

.template-modal-body-assets b {
    font-size: 1rem;
}

.template-modal-body-footer {
    font-size: 1.099rem;
    /* padding: 1rem; */
}

.badge {
    display: inline-block;
    padding: 0.2em 0.4em;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    color: #fff;
    background-color: #17a2b8;
    /* padding: 2px 5px; */
    font-size: 0.899rem;
}

.template-modal-body-footer li {
    position: relative;
}

.template-modal-body-footer li::before {
    content: "";
    position: absolute;
    top: .5rem;
    left: 0;
    height: 0.7rem;
    width: 0.7rem;
    background: rgba(0, 0, 0, .501961);
    border-radius: 100%;
}

.template-modal-body-footer li a {
    margin-left: 1.2rem;
}

/* .fa-paper-plane-top{
    content: '\e20a';
    color: #734BD1;
    width: 20px;
    height: 20px;
} */

.floating_btn {
    text-align: right;
    position: relative;
}

.floating_btn button {
    position: absolute;
    /* top: -20px; */
    right: -20px;
    bottom: -20px;
    border: none;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 0.899remer;
    padding: 5px 15px;
    border-radius: 4px;
    border-top-right-radius: 0;
    transition: all .25s ease-in-out;
}

.searchHeader {
    box-shadow: 0 4px 15px #00000029;
}

.content__header__searchbox {
    display: flex;
    width: 100%;
}

.chatListPage {
    align-items: center;
    justify-content: space-around;
    margin: 0.3rem;
    width: auto !important;
}

.chatListPage .input-group-prepend {
    display: flex;
    height: 2.75rem;
}

.chatListPage .input-group-text {
    line-height: 0 !important;
    padding: 0 !important;
    border-radius: 1.25rem 0 0 1.25rem;
    background-color: #f4f0ff !important;
    border: none;
}

.row--three>.content__header__searchbox input {
    border: none;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
}

.form-control {
    border: none;
}

.form-control:focus {
    box-shadow: none;
}

#templateSearch {
    height: 2.75rem;
    overflow: hidden;
    background-color: #f4f0ff !important;
    border-radius: 1.25rem;
    border: none;
}

.searchTemplateSpinner {
    background-color: white;
    padding: 2px;
}

/* Media Query Mobile Devices*/
@media only screen and (max-width: 768px) {

    .pre_approvetemplate_body {
        height: calc(152% / 1.8);
        overflow-y: auto;
        overflow-x: hidden;
    }

    .pre-approve-modal-box {
        top: 43%
    }
}


.disbaleBtn:disabled {
    background-color: #9c80dc;
}

.pre_approvetemplate_body,
.pre_approvetemplate_head,
.pre-approve-modal-box .modal-header {
    background-color: white;
}

/* @media only screen and (max-width: 1200px){
  .pre-approve-modal-box{
    width: 98%;
  }
} */


/* Media Query iPads Devices*/


@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    .pre_approvetemplate_body {
        height: calc(100% / 1.1);
        overflow-y: auto;
        overflow-x: hidden;
    }

    .pre-approve-modal-box {
        margin-top: 0px;
    }

    .pre-approve-modal-box {
        height: 66% !important;
        top: 36%
    }
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
    .pre_approvetemplate_body {
        height: calc(100% / 1.1);
        overflow-y: auto;
        overflow-x: hidden;
    }

    .pre-approve-modal-box {
        margin-top: 0px;
    }

    .pre-approve-modal-box {
        height: 66% !important;
        top: 36%
    }
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .pre_approvetemplate_body {
        height: calc(100% / 1.1);
        overflow-y: auto;
        overflow-x: hidden;
    }

    .pre-approve-modal-box {
        margin-top: 0px;
    }

    .pre-approve-modal-box {
        height: 66% !important;
        top: 36%
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .pre_approvetemplate_body {
        height: calc(100% / 1.1);
        overflow-y: auto;
        overflow-x: hidden;
    }

    .pre-approve-modal-box {
        margin-top: 0px;
    }

    .pre-approve-modal-box {
        height: 66% !important;
        top: 36%
    }
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
    .pre_approvetemplate_body {
        height: calc(100% / 1.1);
        overflow-y: auto;
        overflow-x: hidden;
    }

    .pre-approve-modal-box {
        margin-top: 0px;
    }

    .pre-approve-modal-box {
        height: 66% !important;
        top: 36%
    }
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    .pre_approvetemplate_body {
        height: calc(100% / 1.1);
        overflow-y: auto;
        overflow-x: hidden;
    }

    .pre-approve-modal-box {
        margin-top: 0px;
    }

    .pre-approve-modal-box {
        height: 66% !important;
        top: 36%
    }
}

.whatsapp {
    border: none !important;
    background: none !important;
    color: var(--primary-color) !important;

}


.template-name {
    display: flex;
    justify-content: space-between;
}

.twenty-four-hr-template {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
}

.Datepicker .MuiFormLabel-asterisk {
    color: var(--primary-color) !important;
}