/* .participant h3{
    position: absolute;
    color: white;
    font-size: 1rem;
    padding: 0.5rem;
    z-index: 2;
} */

/* .participant {
    position: relative;
    vertical-align: middle;
    align-self: center;
    border-radius: 10px;
    overflow: hidden;
    display: inline-block;
    box-shadow: var(--shadow-dark);
    background: #fff;
    animation: show 0.4s ease;
}

.participant video{
    position: absolute;
    right: 0;
    object-fit: cover;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #000;
    border-radius: 10px;
    overflow: hidden;
    left: 0;
    top: 0;
    background-size: cover;
    overflow: hidden;
    -webkit-transition: margin-top 1s ease-in-out;
    -moz-transition: margin-top 1s ease-in-out;
    -o-transition: margin-top 1s ease-in-out;
    transition: margin-top 1s ease-in-out;
}
.participant img{
    position: absolute;
    right: 0;
    object-fit: cover;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #000;
    border-radius: 10px;
    overflow: hidden;
    left: 0;
    top: 0;
    background-size: cover;
    overflow: hidden;
    -webkit-transition: margin-top 1s ease-in-out;
    -moz-transition: margin-top 1s ease-in-out;
    -o-transition: margin-top 1s ease-in-out;
    transition: margin-top 1s ease-in-out;
} */





/* <html><head></head><body>:root {
    --one-row: 100vh;
    --two-rows: repeat(2, calc(100vh / 2));
    --three-rows: repeat(3, calc(100vh / 3));
    --four-rows: repeat(4, calc(100vh / 4));
} */

.video-wrapper h3{
    position: absolute;
    color: white;
    font-size: 13px;
    padding: 4px;
    background: #734bd178;
    z-index: 2;
}
.video-1 {
    grid-area: A;
}
.video-2 {
    grid-area: B;
}
.video-3 {
    grid-area: C;
}
.video-4 {
    grid-area: D;
}
.video-5 {
    grid-area: E;
}
.video-6 {
    grid-area: F;
}
.video-7 {
    grid-area: G;
}
.video-8 {
    grid-area: H;
}
.grid {
    display: grid;
}
.grid--1 {
    grid-template-rows: var(--one-row);
    grid-template-areas: 
    "A";
}
@media (orientation: portrait) {
    .grid--1 {
        grid-template-areas: 
        "A";
   }
}
.grid--2 {
    grid-template-rows: var(--one-row);
    grid-template-areas: 
    "A B";
}
@media (orientation: portrait) {
    .grid--2 {
        grid-template-rows: var(--two-rows);
        grid-template-areas: 
        "A"
        "B";
   }
}
.grid--3 {
    grid-template-rows: var(--two-rows);
    grid-template-areas: 
    "A B"
    "A C";
}
@media (orientation: portrait) {
    .grid--3 {
        grid-template-rows: var(--three-rows);
        grid-template-areas: 
        "A"
        "B"
        "C";
   }
}
.grid--4 {
    grid-template-rows: var(--two-rows);
    grid-template-areas: 
    "A B"
    "C D";
}
.grid--5 {
    grid-template-rows: var(--two-rows);
    grid-template-areas: 
    "A A B B C C" 
    "D D D E E E";
}
@media (orientation: portrait) {
    .grid--5 {
        grid-template-rows: var(--three-rows);
        grid-template-areas: 
        "A A B B"
        "C C D D"
        "E E E E";
   }
}
.grid--6 {
    grid-template-rows: var(--two-rows);
    grid-template-areas: 
    "A B C" 
    "D E F";
}
@media (orientation: portrait) {
    .grid--6 {
        grid-template-rows: var(--three-rows);
        grid-template-areas:
      "A B"
      "C D"
      "E F";
   }
}
.grid--7 {
    grid-template-rows: var(--two-rows);
    grid-template-areas:
    "A A A B B B C C C D D D" 
    "E E E E F F F F G G G G";
}
@media (orientation: portrait) {
    .grid--7 {
        grid-template-rows: var(--three-rows);
        grid-template-areas:
      "A A A B B B"
      "C C C D D D"
      "E E F F G G";
   }
}
.grid--8 {
    grid-template-rows: var(--two-rows);
    grid-template-areas: 
    "A B C D" 
    "E F G H";
}
@media (orientation: portrait) {
    .grid--8 {
        grid-template-rows: var(--four-rows);
        grid-template-areas: 
      "A B"
      "C D"
      "E F"
      "G H";
   }
}
.video-wrapper {
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: relative;
    vertical-align: middle;
    align-self: center;
    overflow: hidden;
    display: inline-block;
    box-shadow: var(--shadow-dark);
    background: #fff;
    animation: show 0.4s ease;
}
.video-wrapper video {
    height: 100%;
    display: block;
    object-fit: cover;
    width: 100%;
    position: absolute;
    right: 0;
    object-fit: cover;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #000;

    overflow: hidden;
    left: 0;
    top: 0;
    background-size: cover;
    overflow: hidden;
    -webkit-transition: margin-top 1s ease-in-out;
    -moz-transition: margin-top 1s ease-in-out;
    -o-transition: margin-top 1s ease-in-out;
    transition: margin-top 1s ease-in-out;
}
.videoOffIcon{
    width: 100%;
    height: 100%;
    background: #c0aeea;
    
    position: absolute;
    /* display: flex; */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .video-wrapper img {
    height: 100%;
    display: block;
    object-fit: cover;
    width: 100%;
    position: absolute;
    right: 0;
    object-fit: cover;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #000;
    
    overflow: hidden;
    left: 0;
    top: 0;
    background-size: cover;
    overflow: hidden;
    -webkit-transition: margin-top 1s ease-in-out;
    -moz-transition: margin-top 1s ease-in-out;
    -o-transition: margin-top 1s ease-in-out;
    transition: margin-top 1s ease-in-out;
} */



.isMobileJoined{
    object-fit: contain !important;
}