p{
    font-size: 16px !important;
}
ListSubheader{
    font-weight: 800 !important;
    color: black;
}

.EmailText{
    height: 500px;
    overflow: auto;

}

.EmailText::-webkit-scrollbar {
  display: none;
}

.file-wrapper{
    /* height: 30px;
    width: 130px; */
    background-color: #e5e5e5;
    /* margin-top: 10px;
    display: flex;*/
    margin: 5px; 
  }

  .attached-media {
    display: flex;
    justify-content: start;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    max-height: 100px;
    padding: 0 20px;
    color: var(--primary-text-color);
  }
  
  .attached-media .media-tb {
    height: 40px !important;
    width: 130px !important;
    background-color: #e5e5e5;
    margin-top: 10px;
    display: flex;
    margin: 5px;
    /* border: 1px solid red; */
  }

.attached-media .media-tb div:last-child {
    display: flex;
    width: 80%;
    justify-content: center;
    align-items: center;
  
}
.Download{
  color: blue;
  font-size: 12px !important;
  text-align: center;
}


  .action-btns {
    z-index: 9999;
  }