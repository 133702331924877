.name-avatar-div{
    display: flex;
    flex-direction: column;
}

.contact-avatar-div,
.name-avatar-div h1{
    display: flex;
  justify-content: center;
}

.contact-avatar-div{
    padding: 0 1rem 1rem 1rem;
}

button:disabled {
  opacity: 0.7;  
}

.name-avatar-div .buttons-div{
    display: flex;
    width: 70%;
    justify-content: space-evenly;
    margin: auto;
}
.contactDetail {
    opacity: 1 !important;
    visibility: visible !important;
}
.name-avatar-div .buttons-div button{
    background-color: var(--primary-color);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contacts-detials-info{
    display: flex;
    flex-direction: column;
}

.name-contact-details-div hr{
    height: 4px;
    opacity: 1.25;
    color: var(--primary-color);
}
.name-contact-details-div h3{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: -13px;
}

.font-md-caps{
    font-size: 1.099rem;
    font-weight: 300;
}
.font-md-title{
    display: flex;
    justify-content: center;
        font-size: 1.099rem;
        font-weight: bold;
}

.font-md-normal{
    font-size: 1rem;
    font-weight: 300;

}

.margin-top{
    margin-top: 2rem;
    margin-bottom: -1rem;
}

.contactDetailsButton > button >svg {
    color: white !important ;
}

@media only screen and (min-width: 768px) and (max-width: 1200px){
    #modalOuter{
        margin-top:var(--marginTop);
        height:100%!important;
        width: 99vw !important;
    }
}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse){
    #modalOuter{
        margin-top:var(--marginTop);
        height:100%!important;
        width: 99vw !important;
    }
}

.marginCtn{
    margin-bottom: 15px;
}

/* .opt-out-in-label-msg{
    text-align: left;
    font-size: 14px;
    color: #FFFFFF;
    background: #734BD1 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
} */

.modal-view{
    padding: 0px;
}

.opt-out-in-label{
    font-size: 1rem;
    font-weight: 500;
    color: #595959;
}

.opt-out-in-avatar-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #734BD1;
    padding-left: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 3px;
}

.opt-out-in-avatar-div .opt-out-in-label-msg{
    text-align: left;
    color: #FFFFFF;
}

.opt-out-in-avatar-div .buttons-div{
    display: flex;
    justify-content: space-evenly;
    margin: auto;
}

.opt-out-in-avatar-div .buttons-div button{
    background-color: var(--primary-color);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.word-break{
    word-break: break-all;
}