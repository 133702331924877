
.responsive-dialog-title{
    display: flex !important;
}

.custom-modal{
    position:fixed;
    
}
.new_chat_modal input{
    height: 1rem;
    overflow: hidden;
}
.new_chat_modal{
    padding: 3rem 2rem 2rem 2rem;
}

.modal-header{
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem 0rem 1rem;

    border-bottom: 1px solid #00000029;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.d-flex {
    display: flex !important;
}

.font-newchat{
    font-size: 1.2rem !important;
    line-height: 2.8rem;
    font-weight: bold;
}

.modal-header h1 {
    color: var(--secondary-text-color);
}

.modal-header .close {
   
   
    float: right;
    font-size: 28px;
    font-weight: bold;
  
    border-radius: 0 0 0 90px;
    opacity: 1;
    text-align: right;

    background-color: transparent !important;
    color: var(--secondary-text-color);
    width: 6.5rem;
    cursor: pointer;
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    
}

.modal-body .selecttype-radio-btns{
    position: relative;
    margin-bottom: 1.2rem;
}

.modal-body .selecttype-radio-btns .selectfont {
    font-size: 2rem !important;
    line-height: 2.5rem;
    color: var(--primary-color) !important;
    font-weight: 700;
   
}

#demo-customized-radios{
    font-weight: bold;
    color: var(--primary-color);
}

.css-hjtp1-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 100vw !important;
    
}

p:not(:last-child) {
    margin-bottom: 10px;
}

.inputRounded{
    border-radius: 100vw !important;
 }

 .form-fields-div{
     display: flex;
     flex-direction: column;
     align-items: normal;
     row-gap: 1rem;
 }
 .form-fields-div .create-btn{
    border: 1px solid transparent;
    border-radius: 4rem;
    text-decoration: none;
    background-color: rgb(115, 75, 209);
    display: inline-block;
    cursor: pointer;
    font-size: 1rem;
    outline: none;
    position: relative;
    transition: all ease-in-out 0.25s;
    color: #fff;
    
    font-weight: 600;
    margin-bottom: 2rem;
    padding: 0.5rem 1.2rem;
    box-shadow: 0px 0px 15px #00000026;
    white-space: nowrap;
    margin: 0 auto;
}

.font-md{
    color: var(--primary-color);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
}

.asso-prescriber-div{
    display: flex;
    flex-direction: column;
}

.css-19285mc-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: var(--primary-color) !important;
}
.css-19285mc-MuiFormLabel-root-MuiInputLabel-root{
    font-weight: bold !important;
}
.css-hjtp1-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #dee2e6a8 !important;
    border-width: 1px !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border-color: #dee2e6a8 !important;
    border-width: 1px !important;
}

.presListDiv{
    position:absolute;
   height: 5rem;
    overflow-y: scroll;
    width: 90% !important; 
    background: white;
    z-index: 3;
    font-size: 20px;
}


.asso-prescriber-div > div > div{
    width: 100%;
}

#presList div{
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    padding: 0.5rem;
    font-weight: 00;
    color: #929292;
}

.template_body_form{
    margin-top: 1rem;
  
}
.template_body_form p{
font-size: 0.899rem;
}
.template_body_form textarea{
    width: 100%;
    max-height: 30vh;
    height: 110px;
    opacity: 1;
    display: block;
}
.template_assets_form{
    margin-top: 1.3rem;
    position: relative;
    border: 1px solid #dee2e6a8 !important;
}
.template_assets_form ul{
    list-style: none;
    font-size: 1.099rem;
    /* margin: 0 1rem; */
    padding: 1rem;
}
.template_assets_form li{
    position: relative;
    /* padding: 1rem; */
    /* border-color: #dee2e6a8 !important; */
}
.template_assets_form li::before{
    content: "";
    position: absolute;
    top:.5rem;
    left: 0;
    height: 0.7rem;
    width: 0.7rem;
    background: rgba(0,0,0,.160784);
    border-radius: 100%;
}
.template_assets_form li a{
    text-decoration: none;
    color: #929292;
    margin-left: 1.2rem;
}
.template_assets_form li a:hover{
    color: var(--primary-color);
}
.template_assets_form label{
    font-size: 0.899rem;
    position: absolute;
    top: -1rem;
    left: 0rem;
    color: var(--primary-color);
    /* font-size: 1.1rem; */
    font-weight: 700;
    background: #fff;
    padding: 0 0.4rem;
}

.newChatModalBody{
    display: flex;
    justify-content: space-between;
}

.width100per{
width: 100%;
}
/* .new-chat-prescriber .createContactAssociate >h1{
 display: none;
} */

.new_chat_modal .MuiBox-root {
    width: 30%;
    position: relative;
    padding: 1.5rem;
}
.new_chat_body{
    height: 450px;
    max-height: 50%;
    overflow-y: auto;
    overflow-x: hidden;
}
.call-type-toggle{
    margin-top: 0.7rem;
}
.call-type-toggle .css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
    color: white;
}
.call-type-toggle .css-d9c359-MuiButtonBase-root-MuiToggleButton-root{
    color: #929292;
}
.call_modal_content .MuiBox-root{
    width:30vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    background: #413479 0 0 no-repeat padding-box;
    background-size: 100% 100%;
    height: 60vh;
    align-items: center;
    text-align: center;
}
.avatar_wrapper{
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}
.avatar_wrapper > div{
    font-size: 1.7rem;
    font-weight: 600;
}

.call_avatar{
    border-radius: 50%;
    overflow: hidden;
    border: 1pxsolidrgba(115,75,209,.301961);
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.call_modal_body{
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.call_avatar img{
    border-radius: 100vh;
    height: 100%;
}
.call_avatar h1{
    width: 7rem;
}

.call_modal_details{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.call_modal_details h4{
    color: #faf8ff;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
    word-wrap: break-word;
}

.call_modal_details h5{
    font-size: 1.2rem;
    color: #ffffff;
}

.call_modal_icons .call_start i, .call_modal_icons .call_end i{
    border-radius: 50%;
    width: 60px;
    height: 60px;
    align-items: center;
    text-align: center;
    color: var(--primary-color);
    display: flex;
    justify-content: center;
}

.call_modal_icons .call_end i{
    border-radius: 50%;
    width: 60px;
    height: 60px;
    align-items: center;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
}

.call_modal_icons .call_start i{
    color: var(--primary-color);
    background-color: white;
    margin-right: .5rem;
}
.call_modal_icons .call_end i{
    background-color: #ee1025;
    /* border-radius: 50%;
    width: 60px;
    height: 60px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center; */
    margin-left: .5rem;
    transform: rotate(225deg);
}
.call_modal_icons {
    padding-top: 2rem !important;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: #faf8ff ;
}
.call_modal_icons > div{

    display: flex;

}
.fa-phone:before {
    content: "\f095";
}
.call_micIcon{
    background-color: #ffffff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-right: 0.5rem;
}

.callCircle_mic_unmuted , .callCircle_mic_muted{
    color: var(--primary-color);
}

/* Media Query */
@media only screen and (max-width: 1200px) {
    .call_modal_content .MuiBox-root{
        width:100vw;
        overflow: hidden;
        display: flex;
        justify-content: center;
        background: #413479 0 0 no-repeat padding-box;
        background-size: 115% 100%;
        height: 100vh;
        align-items: center;
        text-align: center;
    }
  }
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse) {
    .call_modal_content .MuiBox-root{
        width:100vw;
        overflow: hidden;
        display: flex;
        justify-content: center;
        background: #413479 0 0 no-repeat padding-box;
        background-size: 100% 100%;
        height: 100vh;
        align-items: center;
        text-align: center;
    }
  }