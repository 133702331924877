.full-calendar .fc-event {
    background-color: var(--primary-color)!important;
}
.badge-request-icon{
    position: absolute;
    /* bottom: 43rem; */
    margin-left: 26rem;
    font-weight: 600;
    border-radius: 5px;
    /* padding: 0.1rem; */
    top: 6rem;
    font-size: 0.9rem;
    opacity: 1;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: white;
    background-color: #dc3545!important;
}
