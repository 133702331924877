.meeting-container{
    padding: 1rem 0.5rem;
}

.meeting-header{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0rem 0rem 0.5rem 0rem;
}

.meeting-header-details{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0rem 0rem 0rem 0.6rem;
}

.back-icon{
    margin: 0.6rem 0.4rem;
    color: var(--primary-color);
}

.meeting-title{
    font-family: "SF Pro", sans-serif;
    margin: 0 !important;
    font-size: 1.8rem;
    color: #595959;
    font-weight: bold;
}

.meeting-subtitle{
    font-family: "SF Pro", sans-serif;
    margin: 0 !important;
    font-size: 1rem;
    color: #828287;
}

.hide-header{
    display: none !important;
}

.show-header{
    display: flex !important;
}

#chatheader{
    font-size: 1rem;
}

.tabs-container .tab-main{
    display: flex;
}

.tabs-container .tab-main li {
    flex: 1;
    text-align: center;
    font-family: "SF Pro", sans-serif;
    border-bottom: 1px solid;

}

.tab-active{
    color: var(--primary-color);
    background-color: #ffffff;
    border-bottom: 2.5px solid !important;
    font-weight: bold;
}