.saveContactChat{
    overflow-y: scroll;
    overflow-x: hidden;
}

.form-fields-div {
    display: flex;
    flex-direction: column;
    align-items: normal;
    row-gap: 1rem;
}

.css-179z3gj-MuiFormLabel-root-MuiInputLabel-root{
    display: block !important;
}
.disableNumber .MuiInputBase-formControl{
    background-color: #f7f4ff!important;
}
.important{
    color:red;
}
.SaveButtonForchat{
    display: flex;
    justify-content: center;
}
.title-remove-margin{
    margin-bottom: 0 !important;
}

.srep_text_input{
    margin-top: 1rem;
}
@media only screen and (min-width: 768px) and (max-width: 1200px){
    .ipad-top-margin{ 
        margin-top:var(--marginTop);
        height:100%!important;
    }

}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse){
    .ipad-top-margin{ 
        margin-top:var(--marginTop);
        height:100%!important;
    }

}

.srep_text_input .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    width: 100%;
}