.MuiList-root{
    margin: 20px !important;
}
.containter-div{
    height: 100% !important;
}
p >.MsoNormal{
    display: none !important;
}
.gmail-data-list{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    padding: 0.5rem 0.5rem 0.5rem 6rem;
    padding-left:61px 
}   
.gmail-data-list p {
    margin: 0rem !important;
}
.gmail-data-list-item{
    font-size: 0.89rem !important;
    color: #211f1f;
}
.gmail-data-list-subitem{
    font-size: 1rem;
    color: gray ;
}
.inboxList{
    padding: 0 !important;
    margin: 0 !important;
}
.arrowIconForGoogleMail svg{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentColor;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 0.9rem !important;
}
.googleMails{
    /* display: flex;
    justify-content: center;
    align-items: center; */
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    position: relative;
    -webkit-text-decoration: none;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
}