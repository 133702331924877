.presc__searchbox{
    display: flex;
    /* border: 2px solid grey; */
    border-radius: 19px;
    
    box-shadow: 0 1px 1px rgb(0 0 0 / 16%);
    margin-bottom: 2rem;
}
.search-prescriber{
    border: none !important;;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 2rem !important;
    border-bottom-right-radius: 2rem!important;
}
.search-prescriber :active{
    border: none !important;
    outline: none;
}
.search-prescriber :hover{
    border: none !important;
    outline: none;
}
.Datepicker{
    width: 100% !important;
    margin-bottom: 1.5rem !important;
}
.Timepicker{
    width: 100% !important;
    margin-bottom: 1.5rem !important;
}
.phno-txtfield{
    margin-bottom: 1.5rem;
    margin-top: 25px;
}
.meeting-textfield{
    margin-bottom: 1rem;
    margin-top: 25px;
    display: flex;
}
.send-videolink{
    display: flex;
    padding: 1rem 2rem !important;
}
.send-videolink:hover{
    color: var(--primary-color) !important;;
    background-color: rgba(115, 75, 209, 0.3) !important;
}
.selecttype-radio-btns-video{
    margin-bottom: .1rem ;
}
.video-body{
    height: 63vh;
    overflow-y: scroll !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 100vw !important;
}
.SendVideo{
    display: flex;
}

.secPresDiv{
    padding-bottom: 1rem;
}
/* .createContactAssociate{
    margin-bottom: 1.5rem;
} */
@media only screen and (max-width: 500px)
{
.modal-header {
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem 0.5rem 1rem;
    border-bottom: 1px solid #00000029;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}
/* .phno-txtfield .roundborder, .meeting-textfield .roundborder{
    width:360px !important;
} */
}

@media (min-width: 768px) and (max-width: 1024px) {
    .roundborder{
        width: 100% !important ;
    }
}
@media only screen and (min-width: 1200px) and (hover: hover) and (any-pointer: fine){
    .roundborder{
        width: 100% !important ;
    }
}


@media only screen and (max-width: 1200px){
    .video-body{
        height: 34rem;
    }
}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse){
    .video-body{
        height: 34rem;
    }
}