*{
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html{
  scroll-behavior: smooth;
  caret-color: black;
}
body {
 font-family:'SF Pro Display', sans-serif;       
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--primary-text-color);
  overflow: hidden;
  caret-color: black;
   /* padding: 20px;  */
}

code {
  font-family: 'SF Pro Display', sans-serif;    
}

*:focus {
  border:0;
  outline:none;
  box-shadow:none;
 }

h3 :hover{
  background-color: none !important;
}

i :hover{
  background-color: none !important;
}

*::-webkit-scrollbar {
  width: 0.7rem;
}
*::-webkit-scrollbar-thumb{
  background: #d8d8d8;
}
*::-webkit-scrollbar-track{
  background: #f1f1f1;
}
::selection{
  background-color: var(--primary-color);
    color: #fff;
}

/* Colors */
:root {
  
  
  --white-color-100: #EBE7F5;
  --mustard-color: #ffc400;
  --red-color: #ff5001;
  
  --grey-color:#FCFCFC;
  --light-grey: #F8F7FC;
  --light-grey-100: #FBFAFD;
  --light-grey-200:#EDEEED;
  --light-grey-300: #F1F1F1;
  --light-grey-700:#959595;
  --light-gray-500:#D6D6D6;
  --dark-grey: #707070;
  /* new colors */
  --primary-color: #734bd1; /* main theme color */
  --secondary-color: #F5F5F5; /* background color */
  --tertiary-color:#F6780D; /* onactive for icons in slidebar and button in calendar */
  --primary-color-light: #734BD14D; /* */
  --orange-color: #F6780D; /* onactive for icons in slidebar and button in calendar */
  --primary-text-color: #828287; /* primary text color */
  --secondary-text-color: #595959; /* secondary text color*/
  --placeholder-text-color: #828287;
  --secondary-bg-color:#FFE5DD;

  --third-color:#ffffff;
  --white-color: #FFFFFF; 
  --secondary-bg-color:#FFE5DD;
  
  --red-dark:#d00c2a; 
  --light-grey--400:#D4C8F0;
  --black-color:#000000;
  --padding:20;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--placeholder-text-color) !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--placeholder-text-color) !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--placeholder-text-color)  !important;
}

@media only screen and (min-width: 768px) {
  html {
    font-size: 120%;
  }
}

@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse) {
  html {
    font-size: 120%;
  }
}

@media only screen and (min-width: 1200px) {
  html {
    font-size: 100%;
  }
}