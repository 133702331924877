p {
    font-size: 16px !important;
}

ListSubheader {
    font-weight: 800 !important;
    color: black;
}

.jodit {
    width: "80%";
    height: "90%";
    margin: "30px";
    font-family  :'SF Pro Display', sans-serif;       
    font-weight : 400;
    font-size: 1.5rem;
    color: var(--primary-text-color);
    overflow: hidden;
    
}

.form-field {
    display: flex;
    column-gap: 30px;
    align-items: center;
    padding: 0 20px;
}

.form-field>label {
    color: black;
    font-size: 16px;
}

.form-field>input {
    outline: none;
    border: none;
    border-bottom: 1px solid #d7d7d7;
    margin-bottom: 20px;
    width: 100%;
}
.form-field>input:focus {
    border-bottom: 1px solid #734bd1;
}
.form-field>p{
    font-size: 16px;
}