.bar1, .bar2, .bar3 {
    width: 2rem;
    height: 0.2rem;
    background-color: var(--primary-color);
    margin-bottom: 0.6rem;
    transition: .4s;
    border-radius: 10px;
}

.bar2{
    width: 1.2rem;
}
.content__header{
    background: #fff;
    /* padding: 1.4rem 1rem 0rem 2rem; */
    padding: 1rem;
    
    /* width: 100%; */
    z-index: 2;
    
}

.content__header img{
  height: 2.5rem;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
}


.MobileHeader{
    display: none !important;
}
/* for mobile display none */
@media only screen and (max-width: 1200px)
{
  .header-div{

    background:white;
  }
 
  .MobileHeader{
    display: block !important;
  }
  .page-name-div h3 {
    font-size: 2rem!important;
    font-weight: 700;
    line-height: 3.3rem;
    color: var(--secondary-text-color) !important;
    margin-left: 0.5rem;
    margin-bottom: 1rem;
}
}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse)
{
  .header-div{

    background:white;
  }
 
  .MobileHeader{
    display: block !important;
  }
  .page-name-div h3 {
    font-size: 2rem!important;
    font-weight: 700;
    line-height: 3.3rem;
    color: var(--secondary-text-color) !important;
    margin-left: 0.5rem;
    margin-bottom: 1rem;
}
}