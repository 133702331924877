.textarea-wrapper{
    position: relative;
}

.textarea-wrapper textarea{
    font: inherit;
    border: 1px solid #d3d3d370 !important;
    color: #d3d3d370 !important;
    font-size: 1rem;
    padding: 5px;
    width: 99%;
}
.textarea-wrapper textarea:focus{
    color: var(--primary-text-color) !important;
}
.textarea-dynamic{
    font-size: .7rem;
    color: var(--primary-color);
    font-weight: bolder;
    position: absolute;
    top: -10px;
    background-color: white;
    margin: 0px 5px;
}
.multiselect-wrapper{
    position: relative;
    width: 36rem;
}
.template-box{
    position: absolute;
  top: 49%;
  left: 54%;
  transform: translate(-50%, -50%);
  width: 80%;
  border-radius: 1rem;
  padding: 0 3rem 1.5rem 3rem;
  background-color: white;
  border: none 
}
@media screen and (max-width: 1200px) {

    .template-box{
        /* height: 90%; */
        width: 90%;
        top: 50%;
        left: 50%;
        padding: 0;
    }
}
