.header-submenu{
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* justify-content: space-between; */
    background: #E2DAF5 0% 0% no-repeat padding-box;
    border-radius: 12px 12px 0px 0px;
    padding: 0.5rem 1rem;
}


@media only screen and (min-width: 500px) {
    .file_modal .MuiPaper-root{
        width: 100%;
        max-width: 80%;  
    }
}

.body-item{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0.5rem 0;
    padding: 0.5rem 1rem;
}
.modal_title_wrapper{
    /* padding: 0.5rem; */
    border-bottom: 2px solid #ECE3FF;
}
.file_footer{
    display: flex;
    justify-content: flex-end;
}
.file-container-body{
    padding: 0.5rem 1rem;
    border: 2px solid #ECE3FF;
        padding: 0.3rem;
    }

    .input_checkbox{
        margin-right: 0.5rem;
    }
.send_btn{
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    border-radius: 57px;
    opacity: 1;
    color: white;
    font-size: medium;
    font-weight: 600;
    padding: 0.5rem 1rem;
    border: none;
    opacity: 1;
}
.cancel_btn{
    border-radius: 57px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    color: var(--primary-color);
    opacity: 1;
    /* color: white; */
    font-size: medium;
    font-weight: 600;
    padding: 0.5rem 1rem;
    border: 1px solid var(--primary-color);
    margin-right: 1rem;
}

.mycheckbox {
    transform: scale(1.5); /* Adjust the scale value to increase or decrease the size */
}

.files-details{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
    padding:0.5rem;
    box-shadow: 0px 1px 0px #0000001A;
    width: 100vw;
}
.inactiveBtn{
    opacity: 0.3;
}

@media only screen and (max-width: 500px) {
    .file_modal .MuiPaper-root{
        width: 100% !important;
        height: 100% !important;
        margin: 0;
    }
}


.svg-container {
    width: 200px;
    height: 200px;
  }
  
  .my-svg {
    width: 35px;
  }
  
  @media only screen and (min-width: 1200px) {
  .file_modal .MuiPaper-root {
    width: 100% !important;
    max-width: 70%;
}
  }