.ooo-options{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0rem 0rem 1.5rem 1rem;
}


.ooo-view-all-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    overflow-y: auto;
    height: auto;
    max-height: 30rem;
}   



.ooo-list{
    gap: 0rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background: #fff;
    width: 90%;
}

.ooo-list-item{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000040;
    border-radius: 0.5rem;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem;
    margin: 0.5rem;
    width: 100%;
}

.ooo-starttime,.ooo-endtime{
    font-size: 1rem;
    font-weight: bold;
    color: #595959;
    margin: 0 0 1rem 0;
}
.ooo-msg-text{
    font-size: 1rem;
    font-weight: bold;
    color: #595959;
}

.ooo-edit{
    position: absolute;
    top: 10px;
    right: 10px;
    border:none;
    color: #fff;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 1rem;
    white-space: nowrap;
    border-radius: 2rem;
    padding: 0rem 1.5rem;
    opacity: 1 !important;
    background-color: var(--primary-color);
}

.ooo-editoptions{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    margin: 1rem;
}

.ooo-employeeName{
    width: 100%;
}
