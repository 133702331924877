.slider{
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 99;
    background: #fff;
    width: 100vw;
    
    border-right: 1px solid #eff1f2;
    touch-action: none;
    
    /*opacity: 0;
    visibility: hidden;
    
    overflow: hidden;
     */
}

.slider-block__header {
    padding: 1.5rem 1.5rem 1rem 0rem;
    /* background-image: url(/images/Profile\ BG.png); */
    background-repeat: no-repeat;
    background-size: 110%;
    background-position: 50%;
    height: unset;
    /* position: fixed; */
    width: 100%;
    z-index: 5;
}


.slider .bar1,.slider  .bar2,.slider  .bar3 {
    
    background-color: #ffffff;
    
} 

.slider .content__header__bars{
    transform: scaleX(-1);
    margin-left: 1rem;
}

.slider .user-name{
    padding: 1.5rem 1.5rem 0rem 1.5rem;
    color: #ffffff;
}

.user-name h4 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
    font-weight: 100;
    font-size: 1.3rem;
}

.slider-block .mob-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.5rem;
    background: #fff;
    position: fixed;
    bottom: 0;
}
.slider-block .mob-footer img {
    height: 2rem;
}

.slider .avatar-div{
    width: 6rem;
    height: 6rem;
}


.slider-block .mid {
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    min-height: 15rem;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #faf8ff;
    padding: 1rem 0.8rem;
}






/* new */
.mobileSideBar{
    position: fixed;
    width: 100%;
    height: 100% !important;
    background: #ffffff;
    z-index: 10;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}


.mobileSideBar_footer img{
    height: 1.5rem;
    background: transparent !important;
    filter: grayscale(1);
}





.mobileSideBar_header .bar1,.mobileSideBar_header  .bar2,.mobileSideBar_header  .bar3 {
    
    background-color: #ffffff;
    
} 

.mobileSideBar_header .content__header__bars{
    transform: scale(-1);
    display: grid;
    justify-content: end;
}


.mobileSideBar_header .row--five{
    padding-top: 1rem;
    column-gap: 1rem;
    flex-direction: row-reverse;
}
.mobileSideBar_header .user-name{
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.mobileSideBar_main{
    padding-top: 20px;
    padding-bottom: 20px;
}

.mobileSideBarIcons{
    color: var(--primary-color);
    width: 3rem;

}

.mobileSideBar_main_links{
    flex-grow: 2;
}

.mobileSideBar_main_links b,.mobileSideBar_main_logout b{
    font-weight: 600 !important;
}

.mobileSideBar_main_links li{
    padding: 0.3rem 1rem;
}


.mobileSideBar .avatar-div{
    height: 4rem;
    width: 4rem;
    padding: 0rem;
}

.mobileSideBar_main{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    /* height:  calc(100% - (8rem + 6rem)); */
}

.mobileSideBar_main_logout{
    padding: 2rem 1rem;
    border-top: 2px solid #fcf5f5;
}
.mobileSideBar_main_logout img{
    width: 2rem;
    margin-right: 1rem;
    
}
.mobileSideBar_main_logout b{
padding-top:0.5rem ;
}

.mobileSideBar_main_links a {
    text-decoration: none;
    display: flex;
    color: var(--secondary-text-color);
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}
.mobileSideBar_main_links a h4,.mobileSideBar_main_logout h4{
   
    color: var(--secondary-text-color);
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
   gap: 1rem;
}
.homeIcon{
    padding-right: 1.5rem;
}

.Email_SubMenuMobile {
    background: #e9ecef;
    border-radius: 0.5rem;
    /* position: relative; */
    /* margin-top: -15px; */
    /* margin: -1rem 0.8rem 0.8rem 0.8rem; */
    margin: 8px !important;
    cursor: pointer !important;
    margin-right: 20vh !important;
}
.email-list_wrapper{
    margin: 1rem;
    padding-top: 10px;
    padding-bottom: 10px;
}

.email-list_wrapper .active {
    margin-left: -6px !important;
    padding: 4px;
    border-radius: 12px;
}

.email-list_wrapper > svg {
    margin-right: 6px;
}

.email-list_wrapper div{
    font-size: 1.2rem;
}
.mobileSideBar_footer{
    /* z-index: 1000;
    position: relative; */

    z-index: 1000;
    position: fixed;
    background: var(--primary-color);
    display: none;
    height: 4rem;
    width: 100%;
    bottom: 0;
}

@media only screen and (max-width: 1200px) {
    .mobileSideBar_footer{
        /* z-index: 1000;
        position: relative; */
    
        z-index: 1000;
        position: fixed;
        background: var(--primary-color);
        display: flex;
        height: 4rem;
        width: 100%;
        bottom: 0;
    }
    
    /* .mobileSideBar_footer footer{
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0.5rem;
        background: white;
    } */
    
    .mobileSideBar_header{
        padding: 1rem 1rem 1rem 1rem;
        /* background-image: url(/images/Profile\ BG.png); */
        background-repeat: no-repeat;
        background-size: 110%;
        background-position: 50%;
        height: unset;
        /* position: fixed; */
        /* width: 100%; */
        z-index: 5;
    }

}

