.sharepopup{
    position: absolute;
    display: flex;
    /* position: relative; */
    background-color: #0000007f;
    height: 100%;
    width: 100%;
    z-index: 9999;
    left: 0px;
    justify-content: space-around;
   
}
.sharepoup-main{
    position: relative;
    height:600px;
    width:300px;
    background-color: white;
    padding: 20px;
    border-radius: 15px;
}
.sharepopup-header{
    /* border: 1px solid red; */
    background-color: var( --primary-color);
    display: flex;
    color: white;
    padding-left:20px ;
}
.sharepopup-title{
    font-size: 40px;
    /* border: 1px solid green; */
    width:100%
}
.sharepopup-closebtn{
    /* border: 1px solid yellow; */
    width: 30px;
    padding: 0px 0px auto auto;
}
.sharepopup-filedetails{
    /* border: 1px solid blue; */
    padding-left: 30px;
    font-size: 15px;
    background-color: var( --primary-color);
    color: var(--light-grey);
}
.sharepopup-shareduserdiv{
    /* display: flex; */
    margin-top: 20px;
    padding: 10px 20px 20px 20px;
    /* background-color: var(--light-grey-200); */
    height: 70%;
    /* border: 1px solid red; */
}
.sharepopup-shareduser{
    display: flex;
    /* border: 1px solid blue; */
    height: 120px;
    margin:0px 10px;
}
.sharepopup-shareduserTitle{
    font-size: 1rem;
    /* height: fit-content; */
    /* border: 1px solid green; */
    /* margin-right: 10px; */
}
.sharepopup-shareduseravatar{
    margin: 2px;
}
.sharepopup-shareduseravatar-closebtn{
    /* border: 1px solid black;  */
    display: none;
    width: 15px;
    position: relative;
    left: 40px;
    top: -60px;
    border-radius: 17px;
    height: 15px;
    font-size: 11px;
    text-align: center;
    background-color: var(--primary-color-light);
    /* padding: none; */
    color: black;
    /* font-weight: 500;*/
}
.sharepopup-shareduseravatar:hover + .sharepopup-shareduseravatar-closebtn{
    display: block;
    cursor:pointer
}
.sharepopup-shareduseravatar-closebtn:hover {
    display: block;
    cursor:pointer
}


.sharepopup-addshareduserbtn{
    cursor:pointer;
    background-color: #734BD14D;
    color:var('--primary-color');
    font-size:1.5rem;
    width:70px;
    height:70px;
    border-radius: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.sharepopup-addshareduserdiv{
    /* border: 1px solid black; */
    width:100%;
    height: auto;

}

.sharepopup-sharewithuserlist{
    /* border: 1px solid black; */
    
    overflow-y: auto;
    height: 230px;
    margin:0px 10px;
}

.sharepopup-shareuserlistitem{
    /* border: 1px solid black; */
    display: flex;
    padding: 3px 3px 3px 10px;
    margin-top: 2px;
    background-color: white;
    box-shadow: 0px 2px 8px #00000024;
}
.sharepopup-shareuserlistitem div{
    margin-left: 10px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    display: flex;
}
.sharepopup-sharedusersearch{
    /* border: 1px solid black; */

}
.sharepopup-sharedusersearch input{
    width:100%;
    border-radius: 15px;
}
.save-btn{
    background-color: var(--primary-color);
    width: fit-content;
    padding: 0px 10px;
    color: white;
    margin-right: 0px;
    border-radius: 5px;
    margin-left: auto;
    margin-top: 10px;
    cursor: pointer;
}
