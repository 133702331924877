.Email_Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid rgb(136, 68, 68);
    height: 72px !important;
}


.HeaderLeft {
    display: flex;
    align-items: center;
    padding-left: 20px;

    float: left;
    overflow: hidden;

    border: none;
    outline: none;
    /* color: white; */
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit; /* Important for vertical align on mobile phones */
    margin: 0; /* Important for vertical align on mobile phones */
}
.HeaderLeft h2{
    color: var(--secondary-color);
}
/* .active, .HeaderLeft:hover {
    background-color: red;
  } */
.HeaderLeft > img {
    object-fit: cover;
    margin-left: 20px;
    height: 50px;
}

.HeaderMiddle {
    display: flex;
    flex: 0.6;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    border-radius: 50px;

  }

.HeaderMiddle > .fas fa-search i-1p5 {
    color: antiquewhite;
}

.HeaderMiddle > SearchInput {
    border: none;
}

.HeaderRight {
    display: flex;
    padding-right: 20px;
}

.button-86 {
    all: unset;
    width: 100px;
    height: 30px;
    font-size: 16px;
    background: transparent;
    border: none;
    position: relative;
    color: #f0f0f0;
    cursor: pointer;
    z-index: 1;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    left: -48px !important;
    background-color: var(--primary-color);
    border-radius: 3rem;
    font-weight: 700;
  }

  .select-mai{
    text-align: center;
    margin: 8rem;
  }
  /* .button-86::after,
.button-86::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all .4s;
} */

/* .button-86::before {
    transform: translate(0%, 0%);
    width: 100%;
    height: 100%;
    background: #4e4ea5;
    border-radius: 10px;
  } */

  /* .button-86::after {
    transform: translate(10px, 10px);
    width: 35px;
    height: 35px;
    background: #ffffff15;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 50px;
  }
  
  .button-86:hover::before {
    transform: translate(5%, 20%);
    width: 110%;
    height: 110%;
  }
  
  .button-86:hover::after {
    border-radius: 10px;
    transform: translate(0, 0);
    width: 100%;
    height: 100%;
  }
  
  .button-86:active::after {
    transition: 0s;
    transform: translate(0, 5%);
  } */
  

/* .EmailText{
    border:4px solid#734BD1;
    border-radius:12px;
    margin: 2px 0px;
} */

.compose_btn {
    margin-top: 15px !important;
    margin-left: 10px !important;
    margin-bottom: 15px !important;
    text-transform: capitalize !important;
    color: gray;
    padding: 15px !important;
    border-radius: 30px !important;
    box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.75);
}

.compose-btn-wrraper {
    margin-right: 60px;
}


.Email_Sidebar {
    width: 18%;
    flex: 0.3;
    max-width: 300px;
    padding-right: 20px;
    margin-top: 1vh;
    display: grid;
    grid-template-columns: 250px;

    display: none;
    position: absolute;
    background-color:  #ddd;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;

    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}  

.Email_Sidebar:hover {
    background-color: #ddd;
}

.EmailMain_COntent {
    display: flex;
}

.HeaderLeft:hover .Email_Sidebar {
    display: block;
}


.RightMain {
    /* width: 80%; */
    position: relative;
    top: -81vh;
    left: 49vh;
    display: flex;
    border-bottom: 1px solid whitesmoke;
    background-color: white;
    z-index: 999;
    margin-top: 35vh;
}


.Email_Setting {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -35vh;
    left: 18vw;
    background-color: aliceblue;
}

.generic-search-input {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

.generic-search-button {
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    padding-right: 10%;
    background-color: #fff !important;
}


@media only screen and (min-width: 1200px) {
    .h1, h1 {
    font-size: 30px;
    font-weight: 800;
}
}


/* For Mobile View*/


@media only screen and (max-width: 480px) {
    /* .genericSearchDiv {
        display: flex;
        margin-left: -53px;
        width: 134%;
    } */

    .HeaderRight {
        display: flex;
        padding-right: 2px;
    }

    .button-86 {
        width: 54%;
        position: relative;
        top: -2px;
        margin-right: -22px;
    }
     
}

@media only screen and (max-width: 320px) {
    .button-86 {
        padding-right: 1px;
        position: fixed;
        width: 48vw;
        margin-right: 28px;
        top: 85vh;
    }

    .generic-search-input {
        margin-right: -60px;
        width: -16%;
        padding-right: 10px;
    }
}
