p {
    font-size: 16px !important;
}

ListSubheader {
    font-weight: 800 !important;
    color: black;
}

.jodit {
    width: "80%";
    height: "90%";
    margin: "30px";
    font-family  :'SF Pro Display', sans-serif;       
    font-weight : 400;
    font-size: 1.5rem;
    color: var(--primary-text-color);
    overflow: hidden;
    
}

.form-field {
    display: flex;
    column-gap: 30px;
    align-items: center;
    padding: 0 20px;
}

.form-field>label {
    color: black;
    font-size: 16px;
}

.form-field>input {
    outline: none;
    border: none;
    border-bottom: 1px solid #d7d7d7;
    margin-bottom: 20px;
    width: 100%;
}
.form-field>input:focus {
    border-bottom: 1px solid #734bd1;
}
.form-field>p{
    font-size: 16px;
}
.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
    height: 40%;

  }
  .editor-class {
    background-color:white;
    padding: 1rem;
   /* border: 1px solid #ccc; */
  }
  .toolbar-class {
    border: 1px solid #ccc;
  }
  .form-field input{
    font-size: medium;
  }

  .send-btn-wrraper {
    position: fixed;
    bottom: 0;
    transform: translateX(-20%);
    width: 100%;
    margin: auto !important;
    display: block !important;
    text-align: center;
  }
  .demo-wrapper{
    font-size: medium;
    color: var(--secondary-text-color);
  }

  .draft-to-input .MuiInputBase-formControl{
    border: none !important;
    width: 55vw;
  }

  .attached-media {
    display: flex;
    justify-content: start;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    max-height: 100px;
    padding: 0 20px;
    color: var(--primary-text-color);
  }
  
  .attached-media .media-tb {
    height: 30px;
    width: 130px;
    background-color: #e5e5e5;
    margin-top: 10px;
    display: flex;
    margin: 5px;
    /* border: 1px solid red; */
  }