

input{
    box-sizing:border-box
}
.row--three>.content__header__searchbox input:focus{
    border:0;
    outline:none;
    box-shadow:none;
}
.badge-container{
    margin-top: -4px;
    text-align: end;
    margin-right: .5rem;
 
}
.badge{
    font-size: 0.899rem;
    border-radius: 100% !important;
    padding: 0.45rem !important;
    background-color: var(--tertiary-color) !important;
    color: white;
    line-height: .5 !important;
    visibility: hidden;
    visibility: visible;
}

@media only screen and (min-width: 1200px) and (hover: hover) and (any-pointer: fine){
    .row--three>.content__header__searchbox {
        display: flex;
        padding: 0px !;
        margin-bottom: 0.3rem;
    }
}

.searchHeader{
box-shadow: 0 4px 15px rgb(0 0 0 / 16%);
padding: 0.5rem;
background-color: var(--secondary-color);
}


.row--three>.content__header__searchbox .search {
    border: none;
}
.row--three>.content__header__searchbox  input {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border: none;
}

.row--three #basic-addon1, .row--three #basic-addon3 {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;

}
@media only screen and (min-width: 1200px) and (hover: hover) and (any-pointer: fine){
   
    .row--three #basic-addon1, .row--three #basic-addon3 {
        background-color: #fff!important;
        border: none;
    }
}


.chatListPage{
    align-items: center;
    width: unset !important;
    margin: 0.3rem;
    display: flex;
    justify-content: space-around;
}

.chatListPage .input-group-text{
    line-height: 0 !important;
    padding: 0% !important;
}
.chatListPage .input-group-prepend{
    display: flex;
    height: 2.75rem;
}

.chatListPage .create_newchat {
    background: var(--primary-color);
    border-radius: 100vw;
    color: #fff;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.2rem;
    font-size: 18px;
    margin: 0 20px 0 20px;
}
.medical-icon::before{
    font-size: xx-large;
}


.chatListItem-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 2px 8px #00000024;
    border-radius: 4px;
    height: 4rem;
    margin: 1rem;
    padding: 0 1rem 0 0;
}
.chatListItem-div .avatar-div{
    display: flex;
    padding: 0 .6rem 0 1rem !important;
    align-items: center;
   
}

.chatListDetails{
    padding: 0.5rem;
    padding-right: 0px;
    padding-left: 0px;
    display: inline-grid;
}

.chatListDetails >div{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;
}
.chatListDetails >h4, .chatListDate >h4 { 
    margin-bottom: 0;
}
.chatListDetailsName{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 1.099rem;
    font-weight: bold;
}
.ChatListMsg{
    font-size: 1rem;
}

.chatListDate{
    display: flex;
    flex-direction: column;
}
.msgDate{
    margin-top: 0.3rem;
    font-size: 0.899rem;
}
.chatListDetails{
    width: 75%;
}
.chatListName{
     width: 18%; 
}

.content__header__searchbox{
    display: flex;
}

.chatListDetails h4{
    justify-content: flex-start;
    color: var(--primary-text-color);
}

.chatListItem-div > .d-flex{
    width: 80%;
}

@media only screen and (min-width: 600px) and (max-width:1200px) {

.chatListDiv .floating{
    position: fixed;
    bottom: 3rem;
    right: 3rem
}

}

@media only screen and (max-width: 600px) {

.chatListDiv .floating{
    position: fixed;
    bottom: 3rem;
    right: 1rem
}

}
/* for Mobile */
@media only screen and (max-width: 1200px) {
    .header-div {
        grid-template-columns: none;
        background: white;
        box-shadow:none !important;
    }
    .chatListItem-div{
        margin: 0;
        box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid #00000024;
    }
    .chatListItem-div > .d-flex{
        width: 83%;
    }
    .chatListDiv{
        width: 100%;
    }
    .chatListDiv .search-chats{
        background-color: var(--secondary-bg-color)!important;
    }
   
    .chatListPage .content__header__searchbox{
        width: 95%;
    }
    .floating-button{
        background-color: var(--primary-color);
        color: #ffffff;
        border: none;
        border-radius: 100vw;
        width: 1.5rem;
        height: 1.85rem;
        cursor: pointer;
        box-shadow: 0px 2px 8px #00000052;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        padding: 1rem;
    }

    .searchHeader{
        padding: 0.5rem;
        background-color:#ffffff;
        border: 2px solid #dcd8d840;
    }
    
  }
  .floating-button-chatlist{
    background-color: var(--primary-color);
    color: #ffffff;
    border: none;
    border-radius: 100vw;
    width: 1.65rem;
    height: 1.8rem;
    cursor: pointer;
    box-shadow: 0px 2px 8px #00000052;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    padding: 1rem;
}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse) {
    .header-div {
        grid-template-columns: none;
        background: white;
        box-shadow:none !important;
    }
    .chatListItem-div{
        margin: 0;
        box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid #00000024;
    }
    .chatListDiv{
        width: 100%;
    }
    .chatListDiv .search-chats{
        background-color: var(--secondary-bg-color) !important;
    }
    .chatListDiv .floating{
        position: fixed;
        bottom: 3rem;
        right: 1rem
    }
    .chatListPage .content__header__searchbox{
        width: 95%;
    }
    .floating-button-chatlist{
        background-color: var(--primary-color);
        color: #ffffff;
        border: none;
        border-radius: 100vw;
        width: 1.65rem;
        height: 1.8rem;
        cursor: pointer;
        box-shadow: 0px 2px 8px #00000052;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        padding: 1rem;
    }

    .searchHeader{
        padding: 0.5rem;
        background-color:#ffffff;
        border: 2px solid #dcd8d840;
    }
    
  }
  .chatlistbackground{
    background: var(--secondary-color) !important;
  }

  .chatlistbackground .infinite-scroll-component__outerdiv .infinite-scroll-component {
    overflow-x: hidden !important;
  }