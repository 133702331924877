
@media (max-width: 1024px) {
    .MuiModal-root .loding-modal{
        width: 30% !important;
        height: auto !important;
        border-radius: 2vw;
       }
.ProgressVal{
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    font-size: 1rem;
}
}
.MuiModal-root .loding-modal{
       height: auto !important;
   }
.progress_svg{
    color: var(--primary-color) !important;
}
.ProgressVal{
    font-size: 1rem;
}