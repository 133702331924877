.ooo-options{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0rem 0rem 1.5rem 1rem;
}

.ooo-view-all{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
   max-height: fit-content;
}   



.ooo-list{
    gap: 0rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background: #fff;
    width: 90%;
    height:100%;
}

.ooo-list-item{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000040;
    border-radius: 0.5rem;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem;
    margin: 0.5rem;
    width: 100%;
}

.ooo-starttime,.ooo-endtime{
    font-size: 1rem;
    font-weight: bold;
    color: #595959;
    margin: 0 0 1rem 0;
}
.ooo-msg-text{
    font-size: 1rem;
    font-weight: bold;
    color: #595959;
}

.ooo-edit{
    position: absolute;
    top: 10px;
    right: 10px;
    border:none;
    color: #fff;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 1rem;
    white-space: nowrap;
    border-radius: 2rem;
    padding: 0rem 1.5rem;
    opacity: 1 !important;
    background-color: var(--primary-color);
}

.ooo-editoptions{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    margin: 1rem;
}
.video-body{
    height: 63vh;
    overflow-y: scroll !important;
}
@media only screen and (max-width: 500px)
{
.modal-header {
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem 0.5rem 1rem;
    border-bottom: 1px solid #00000029;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}
}
.roundborder{
    width: 100% !important ;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .roundborder{
        width: 100% !important ;
    }
}
@media only screen and (min-width: 1200px) and (hover: hover) and (any-pointer: fine){
    .roundborder{
        width: 100% !important ;
    }
}


@media only screen and (max-width: 1200px){
    .video-body{
        height: 34rem;
    }
}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse){
    .video-body{
        height: 34rem;
    }
}