.msg{
    border-radius: 5px;
    width: fit-content;
    max-width: 40rem;
    min-width:100px;
    color:var(--secondary-text-color);
    font-size: 16px;
    font-family: "SF Pro", sans-serif;
    margin-bottom: 3px;
/* padding: 12px 12px 0rem 12px; */
    border : 1px solid transparent;


    

}
.img-edge{
    pointer-events:none;
}
.img-media-files{
    cursor:pointer;
    pointer-events:auto;
}

.preview-tab-button{
    color: #8b8597;
    text-decoration: none !important;
    padding: 0.5rem;
/* background-color: #d7d9dc; */
    text-align: center;

}
.sender .msg{
    margin-left: auto;
    background: #e2daf5;
}
.received .msg {
    margin-right: auto;
    background: white;
}
.msg-row{
    display: flex;
    padding: 24px 24px 8px 24px;
    
   
}
.msg-row.sender {
    justify-content: right;
}

.msg-row.received {
    justify-content: left;
}
.msgtext {
    padding-top: 10px;
}

.sender .msgtext{
    padding-right: 15px;
}
.received .msgtext {
    padding-left: 15px;
}
.msg-content{
    font-size: 1rem;
    line-height: 1.5rem;
    margin:  10px 10px 0px 10px;
    white-space: pre-wrap;
}
.msg-content p{
    word-break: break-word;
}
.msg-details{
    font-size: 12.8px;
    align-items: right;
    justify-content: right;
    text-align: right;
/* margin: 10px; */
   
    
}
.avatar-block {
    border-radius: 50%;
    height: 37px;
    width: 37px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: white;
    display: flex;
    color: var(--primary-text-color);
    box-shadow: 0px 4px 15px #00000029;
    border: 1px solid rgba(115,75,209,.301961);
}

.msg-time{
    padding-left: 0.3rem;
}
.avatar-area{
    
   
}
.dateBanner {
    position: relative;
    padding: 0 1.5rem;
    margin-bottom: 8px;
}
.dateBanner .h4-date {
    background: var(--date-banner-bg-color);
    color: var(--primary-text-color);
    left: 0;
    border-radius: 100vw;
    margin-left: auto;
    margin-right: auto;
    padding: 2px 1rem;
    position: absolute;
    right: 0;
    text-align: center;
    width: fit-content;
    z-index: 10;
    margin-top: 0.3rem !important;

    font-size: .9rem !important;
    font-weight: normal !important;

}

.dateBanner .vis-hidden {
    visibility: hidden;
}
.dateBanner hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.7);
}
.media-files {
    margin: 10px;
}
.media-files img{

    width: 100%;

}
.media-files .med {
    width: 100%;
    height: auto;
    max-width: 400px;
    max-height: 400px;
}
.attachcard{
    text-decoration: none;
}
.attachcard a{
    
    color: var(--secondary-text-color);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 2px;
}
.sender .attachcard a{
    background-color: var(--white); 
}
.received .attachcard a{   
    border: 1px solid #E7E7E7;
}
.attachcard a:hover{
    color: #4F2AA5;
}
.attachcard a:hover h5{
    color: #4F2AA5;
}
.attachcard h5{
    color: var(--secondary-text-color);
    text-decoration: none !important;
    margin: 4px;
}
.attachcard i{
    margin: 4px;
}

@media only screen and (max-width:700px) {
    .sender .attachcard a{
        border: 1px solid #E7E7E7;
    }
    .received .attachcard a{
        background-color: var(--white); 
    }
}

