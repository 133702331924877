.sidebar-container{
    display: grid;
    grid-template-rows: 10% 90%;
    background: var(--secondary-color) !important;
}

.zing-logo-div{
    grid-row: 1;
    grid-column: 1;
    background: #fff !important;
    gap: 0;
    box-shadow: 0px 4px 15px #00000029;
}

.logo-img{
    /* height: 55%; */
    padding: 0.9rem 2rem !important;
}

.logo-img img{
    border-style: none;
    width: 100%;
}

.menu-btn-div{
    overflow-x: hidden;
    padding: 1.2rem 0rem;
    height: calc(100vh - 9rem);
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    scrollbar-width: thin;
    flex-wrap: wrap;
    margin-bottom: 0;
    list-style: none;
}

ul{
    margin: 0;
    width: 100%;
    padding: 0;
    cursor: pointer;
}

.menu-btn-div > ul > a >h3{
    font-size: 0.899rem;
    font-weight: bold;
    background: none !important;
}



.menu-btn-div > ul > a{
    
    text-align: center;
    display: block;
    border-radius: 1rem;
    padding: 1.2rem 0.4rem;
    /* width: 3.6rem; */
    color: var(--primary-color);
    height: max-content;
    margin: 0.8rem;
    text-decoration: none;
    cursor: inherit;

}

.menu-btn-div > ul > a:not(:last-child){
margin-bottom: 1rem;
}

.sidebar-container >.menu-btn-div > ul > a:hover{
    background: #e1d5ff !important;
    box-shadow: 0px 4px 15px #00000029;
    color: var(--secondary-text-color);
} 


.active {
    color: var(--tertiary-color) !important;
    background: #fff !important;
}

.Email_SubMenu{
    background: #e9ecef;
    border-radius: 0.5rem;
    /* position: relative; */
    /* margin-top: -15px; */
    /* margin: -1rem 0.8rem 0.8rem 0.8rem; */
    margin: 8px !important;
    cursor: pointer !important;
}
.Email_SubMenu .list-wrapper div a{
    text-decoration: none;
    color: var(--secondary-text-color);
    font-size: 14px;
}

.Email_SubMenu .list-wrapper .active {
    margin-left: -6px !important;
    padding: 7px;
    border-radius: 12px;
}

.Email_SubMenu ul{
    text-align: center;
}

.Email_SubMenu:hover {
    background-color: #ddd;
}

.Email_SubMenu ul li svg {
    margin-bottom: -2px;
    margin-top: -9px;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    margin-left: 37px;
    position: relative;
    top: 20px;
}

.Email_SubMenu ul li {
    margin-bottom: -11px;
    margin-right: -50px;
    margin-left: -33px;
    font-size: 16px;
    position: relative;
    top: -10px;
}

.list-wrapper {
    padding-left: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.activeLink:hover {
    color: var(--tertiary-color) !important;
    background: #fff !important;
}


.menu-btn-div::-webkit-scrollbar {
    width: 0.2rem;
  }



/* for mobile */


