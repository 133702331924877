.media-main-div{
    display: flex;
    flex-direction: row;
    width: 100%;
    background: #F7F7F7;
}

.media-sidebar-div{
    width: 22%;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px #00000029;
}

.media-docviewer-div{
    flex-grow: 1;
}
.activeformtab{
    border-bottom: 4px solid var(--primary-color);
    background: transparent !important;
}
.media-sidebar-innerDiv{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 1.8fr;
    height: 100%;
}

.searchbar-div{
   grid-row: 1;
   height: 4rem;
}
.folder_title{
    color: var(--primary-text-color);
    font-size: medium;
    font-weight: 600;
}
.error_file_msg{
    background: rgb(235, 57, 57);
    font-size: 1rem;
    align-items: center;
    color: white;
    text-align: center;
    position: absolute;
    bottom: 1rem;
    /* width: 22%; */
    padding: 0.5rem 1rem;
}

.search-input{
    border: 2px solid #e1e7ff;
    background: #f6f8ff;
    width: 17vw;
    border-radius: 100vw;
    z-index: 2;
}


.search-input-dropdown{
    width: 15vw;
    border-radius: 2vw;
    position: relative;
    top:-38px;
    max-height: 80vh;
}

.search-input-border{
    border-radius: 2vw;
}

.search-input-dropdown-title{
    font-size: .7rem;
    font-weight: 600;
    color: #151220;
    /* border-radius: 100vw; */
}

.search-input-dropdown-path{
    font-size: .7rem;
    /* border-radius: 100vw; */
}


.search-input-dropdown-icon{
    width: 15px;
    /* border-radius: 100vw; */
}




.searchbar-input-div-media{
    /* background: #f6f8ff;
    border: 2px solid #e1e7ff; */
    font-size: 1rem;
    color: #828287;
    letter-spacing: .72px;
    border-radius: 100vw;
    padding: 8px 1rem;
    margin: 0.6rem;
    display: flex;
    gap: 0.5rem;
}

.searchbar-input-div{
    background: #f6f8ff;
    border: 2px solid #e1e7ff;
    background: #f6f8ff;
    width: 17vw;
    border-radius: 100vw;
}

.searchbar-input-div{
    /* background: #f6f8ff; */
    /* border: 2px solid #e1e7ff; */
    font-size: 1rem;
    color: #828287;
    letter-spacing: .72px;
    border-radius: 100vw;
    padding: 8px 1rem;
    margin: 0.6rem;
    display: flex;
    gap: 0.5rem;
}

.searchbar-input-div img{
    width: 1rem;
}

.docs-search-input{
   
    border: 0;
    outline: none;
    width: 100%;
    background: none;
}
.disabled_icon{
    opacity: 0.3;
}


.tabs-main-div{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 1.8fr;
    /* height: 5rem; */
}
.tabs-heading-div{
    grid-row: 1;
    background: #F0ECFA;
}
.tabs-inner-div{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    font-size: 1.2rem;
}

.doctab{
    width: 33.33%;
   cursor: pointer;
    padding: 0.8rem;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    font-weight: 700;

}

.activedoctab{
    border-bottom: 4px solid #734bd1;
    background: transparent !important;
    color: #734bd1;
    font-weight: bold;
}

.doc-list-div{
    padding: 5px 0rem 5px 0rem;
}

.listitem-div{
    padding: 0.6rem 1.2rem 0.6rem 1.2rem;
    height: 2rem;
    border: 0px solid;
    border-radius: 0px;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    justify-content: space-between;
    
}
.listitem-width{
    width: unset !important;
}
.withBg{
    background-color: #E2DAF5;
    /* padding-left: 1rem;
    width: 100%; */
}

.doc-list-div .docs-ul{
    overflow-y: scroll;
    height: calc(100vh - 195px);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.doc-list-div .docs-ul::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
.doc-list-div .docs-ul {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    position: relative;
  }

.docs-li{
    font-size: 1rem;
    display: flex;
    align-items: center;
    color: #595959;
}
/* .file-options{
    display: none;
} */
.file-options{
    display: flex;
    margin-right: 1rem;
    border: none;
    background: transparent;
}
.options-div{
    position: absolute;
    /* width: rem; */
    padding-right: 0.5rem;
    /* height: 4rem; */
    display: block;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    background: #FFFFFF;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
    right: 7px;
    z-index: 999;
    margin-right: 1rem;
    font-size: 0.8rem;
}

.media-page-heading{
    display: none;
}
.search-btn{
    display: none;
}
.moreoptions_icons{
    display: none;
}
.annoted_file_btn{
    /* border: none; */
    font-size: medium;
    /* padding: 0.2rem 0.5rem; */
    /* background-color: var(--primary-color); */
    color: var(--primary-color);
    /* border-radius: 0.5rem; */
    /* opacity: 1; */
}

/* for Mobile */
@media only screen and (max-width: 1200px) { 

   .docs-li{
    justify-content: space-between;
   }
    .only_mobile{
     display: block;
    }
    .media-main-div{
        height: 100vh;
    }

    .only_desktop{
        display: none !important;
    }
    .media-sidebar-div{
        width: 100%;
        box-shadow:none;
    }
    .searchbar-div{
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #595959;
        padding: 0 1rem;
    }

    .search-btn{
        display: block;
        background: transparent;
        border: none;
        font-size: 0.899rem;
    }

    .searchicon{
         color: #828287;;
    }

    .media-page-heading{
        font-size: 2rem;
        font-weight: 700;
        display: block;
    }

    .tabs-heading-div{
        background:#FFFFFF;
    }
    .tabs-inner-div{
        height: auto;
    }

    .activedoctab {
        color: var(--primary-color) !important;
        border-bottom: 4px solid var(--primary-color);
        background: transparent !important;
        font-weight: bold;
    }
    
    .doc-list-div{
        padding: 0;
        overflow-y: auto;
    }

    .listitem-div{
        height: 5rem;
        border-bottom: 1px solid #f0f0f0;
        border-radius: 0;
        display: flex;
        
    }

    /* .searchbar-input-div{
        display: none;
    } */

    .media-docviewer-div{
        /* display: none; */
    }
    

    .tabs-main-div{
        grid-template-rows: 0fr 1.8fr;
        /* overflow-y: auto; */
        height: 90vh;
    }

    .doc-list-div .docs-ul{
        height: 98%;
        overflow-y: initial;
    }


    

   
    .circle-svg{
        width: 1rem;
        height: 1rem;
    }
    
    .docviwer-mob-div{
        position: absolute;
        /* height: 100%; */
        /* width: 100%; */
        /* top: 20px;
        left: 20px;
        right: 20px; */
        background-color: white;
        display: block;
        z-index: 9;
    }

    
    .webviewerMobile{
        height: 87%;
    }
    .docviwer-flex-div{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 1rem 0;
        gap: 1rem;
    }
    .measurement-container,.DocumentContainer{
        background-color: white !important;
    }

}
/* @media only screen and (max-width: 1200) {
    .folderContainer {
        grid-template-columns: repeat(4,auto) !important;
        height: calc(100vh - 193px) !important;
        padding-bottom: 0 !important;
        margin-bottom: 1rem;
    }
} */
.desktop_folder_rename{
    width: 8rem;
    font-size: small;
    border: 1px solid lightgray;
    /* border: none; */
    /* border-bottom: 1px solid; */
    padding: 0.2rem;
}
@media only screen and (max-width: 767px) {
    .folderContainer {
        /* grid-template-columns: repeat(3, 6.5rem) !important; */
        grid-template-columns: repeat(3,auto) !important;
        grid-template-rows: repeat(1, 7.5rem) !important;
        height: calc(100vh - 210px) !important;
        padding-bottom: 0 !important;
        margin-bottom: 1rem;
    }
    #item, .add_new_item{
        /* border: 1px solid #E2DAF5;
        border-radius: 5px; */
        display: block !important;
        /* justify-content: center; */
        height: 5rem !important;
        width: 5rem !important;
        text-align: center;
        position: relative;
        /* align-items: center; */
    }
    .icon_mobile_view{
        margin-top: 1rem;
    }
    .icon_scale{
        transform: scale(1);
    }
    #item >svg{
        font-size: 3rem;
        margin-top: 0.3rem;
    }
    .content-block{
        height: 7rem !important;
    }
    .mobile_more_option{
        position: absolute;
        right: 0%;
        bottom: 3%;
        color: var(--primary-color);
    }
    .heigt_container{
        height: calc(100vh - 274px) !important;
    }
    .mobile_heigt_container{
        height: calc(100vh - 326px) !important;
    }
}

@media (min-device-width:1200px) {
    .heigt_container{
        height: calc(100vh - 322px) !important;
    }
}
@media (min-device-width:768px) and (max-device-width:1024px){
    .folderContainer {
        /* grid-template-columns: repeat(3, 6.5rem) !important; */
        grid-template-columns: repeat(5,auto) !important;
        grid-template-rows: repeat(1, 7.5rem) !important;
        height: calc(100vh - 220px) !important;
        padding-bottom: 0 !important;
        margin-bottom: 1rem;
    }
    #item, .add_new_item{
        /* border: 1px solid #E2DAF5;
        border-radius: 5px; */
        display: block !important;
        /* justify-content: center; */
        height: 5rem !important;
        width: 5rem !important;
        text-align: center;
        position: relative;
        /* align-items: center; */
    }
    .icon_mobile_view{
        margin-top: 1rem;
    }
    .icon_scale{
        transform: unset;
    }
    #item >svg{
        font-size: 3rem;
        margin-top: 0.3rem;
    }
    .content-block{
        height: 7rem !important;
    }
    .mobile_more_option{
        position: absolute;
        right: 0%;
        bottom: 3%;
        color: var(--primary-color);
    }
    .heigt_container{
        height: calc(100vh - 340px) !important;
    }
    .mobile_heigt_container{
        height: calc(100vh - 402px) !important;
    }
}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse) { 

    .media-main-div{
        height: 100vh;
    }

    .media-sidebar-div{
        width: 100%;
        box-shadow:none;
    }
    .searchbar-div{
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #595959;
        padding: 0 1rem;
    }

    .search-btn{
        display: block;
        background: transparent;
        border: none;
        font-size: 0.899remer;
    }

    .searchicon{
         color: #828287;;
    }

    .media-page-heading{
        font-size: 2rem;
        font-weight: 700;
        display: block;
    }

    .tabs-heading-div{
        background:#FFFFFF;
    }
    .tabs-inner-div{
        height: auto;
    }

    .activedoctab {
        color: var(--primary-color) !important;
        border-bottom: 4px solid var(--primary-color);
        background: transparent !important;
        font-weight: bold;
    }

    .doc-list-div{
        padding: 0;
    }

    .listitem-div{
        height: 5rem;
        border-bottom: 1px solid #f0f0f0;
        border-radius: 0;
    }
    .icon_scale{
        transform: scale(1);  
    }

    /* .searchbar-input-div{
        display: none;
    } */

    .media-docviewer-div{
        /* display: none; */
    }
    

    .tabs-main-div{
        grid-template-rows: 0fr 1.8fr;
    }

    .doc-list-div .docs-ul{
        height: 85%;
    }


    

   
    .circle-svg{
        width: 1rem;
        height: 1rem;
    }
    
    .docviwer-mob-div{
        position: absolute;
        /* height: 100%; */
        /* width: 100%; */
        /* top: 20px;
        left: 20px;
        right: 20px; */
        background-color: white;
        display: block;
        z-index: 9;
    }

    
    .webviewerMobile{
        height: 85%;
    }
    .docviwer-flex-div{
        display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2rem 0;
    }
    .measurement-container,.DocumentContainer{
        background-color: white !important;
    }

}

.presentButton{
    font-size: 0.8rem;
    background:var(--primary-color);
    padding: 0.3rem;
    border-radius: 5px;
    color: white;
    margin-right: 1rem;
}

.option_icon_wrapper{
    width: 2rem;
}
.option_icon_wrapper >img{
    width: 100%;
}
#right-menu, .doc-list-div{
    /* position: relative; */
}
.folderContainer{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 6.5rem);
    overflow-y: scroll;
    height: calc(100vh - 258px);
    padding-top: 1rem;
}
.media-icons-wrapper{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    color: var(--primary-color);
    background-color: #F7F7F7;
}

.folderitem {
    /* align-items: center; */
    /* margin: 0 0rem 0 0rem; */
    width: inherit;
    /* border: 1px solid #ccc; */
    /* border-radius: 8px; */
    background: #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: clip;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

#item, .add_new_item{
    border: 1px solid #E2DAF5;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    height: 4rem;
    width: 4rem;
    align-items: center;
}

.folderName{
    word-wrap: break-word;
    width: 100%;
    justify-content: center;
    display: flex;
    color: #151220;
    margin-top: 5px;
    font-size: 10px;
}
.content-block{
    height:100px;

}
/* .active_file{
    background: #E2DAF5 0% 0% no-repeat padding-box;
} */

.docviwer-mob-backBtn{
    display: flex;
    border: none;
    margin-left: 1rem;
    background-color: transparent;
    color: var(--primary-color);
    font-size: 1rem;
    top: .3rem;
}

.folderName{
    word-wrap: break-word;
    width: 100%;
}


.add-files{
    border:none !important;
    /* border-radius: 10px ; */
    /* height:90px; */
    width: 80px;
    font-size: 15px;
    padding: 0px;
    margin: 5px;
    height: 100px;
    padding-top: 0.2px;
}
.upload-media-btn{
    /* width: fit-content; */
    /* height: 30px; */
    background-color: var(--primary-color);
    color:white;
    /* padding-left: 10px;
    padding-right: 10px; */
    border-radius: 25px;
    /* margin: 10px; */
    font-size: medium;
    text-align: center;
    padding: 5px 20px;
    /* position: absolute;
    bottom: 0; */

}

.upload-btn-wrapper{
     height: 4rem;
     background-color: #F7F7F7;
    display: flex;
    align-items: center;
    justify-content: center;

}
.upload-media-button{
    /* height: 30px; */
    /* background-color: var(--primary-color); */
    color:var(--primary-color);
    /* padding-left: 10px;
    padding-right: 10px; */
    /* border-radius: 10px; */
    /* margin: 10px; */
    /* font-size: 20px; */
    text-align: center;  
}
.close-btn{
    display: flex;
    z-index: 1000;
    position: relative;
    bottom: 70px;
    left: 50px;
    
    background-color: #dfd5f7;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    align-content: space-around;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0px;
    margin: 0px;

}
.top-details{
    /* border-radius: 0px 0px 8px 8px; */
    margin: 0px;
    /* height: 15%; */
    background-color:#dfd5f7;
    font-size: 10px;
}
.file-details{
    /* border: 1px solid red ; */
    border-radius: 0px 0px 8px 8px;
    margin: 0px;
    height: 20%;
    /* background-color:#dfd5f7; */
    font-size: 10px;
    justify-content: center;
    display: flex;
}
#addfiletitle .file-details{
    display: flex;
    justify-content: center;
}
.fileName{
    white-space: nowrap;      /*keep text on one line */
    overflow: hidden;         /*prevent text from being shown outside the border */
    text-overflow: ellipsis;  /*cut off text with an ellipsis*/
}
.file-icon{
    /* height: 80%; */
    display: flex;
    /* align-items: center; */
    justify-content: space-around;
    align-content: space-around;
    flex-wrap: wrap;
    font-size: 40px;
    color: var(--primary-color) !important;
}
.folder-rename{
    width: 80px;
    font-size: 12px;
    border: 1px solid lightgray;
}
.folder-rename:focus{
    border:1px solid gray;
}

.item-options-div{
        /* display: none; */
    /* border: 1px solid black; */
    border-radius: 4px;
    height: 11px;
    width: 43px;
    position: relative;
    top: 20px;
    left: 11px;
    font-size: 11px;
    padding-left: 4px;
}
.item-options{
    display: none;
    justify-content: space-between;
    /* border: 1px solid black; */
    border-radius: 4px;
    height: 11px;
    width: 43px;
    font-size: 11px;
    /* padding-left: 4px; */
    /* background-color: #a5a4a4; */
    /* color: white; */
    
}

.content-block:hover .item-options{
    /* border:1px solid black !important; */
    display: flex;
    justify-content: space-between;
}