.aoc-header{
    display: flex;
    height: 2%;
    align-items: center;
    padding: 1.5rem;
    border: 2px solid #dcd8d840;
}
.aoc-container{
    display: flex;
    width: auto;
    height: auto;
    flex-direction: column;
    flex-grow: inherit;
    background: var(--secondary-color) !important;
}

.aoc-list-item:nth-child(1), .pres-name{
    width:30%;
}
.aoc-list-item:nth-child(2), .product-name{
    width: 40%;
}
.aoc-list-item:nth-child(3), .status{
    width: 20%;
}
.aoc-list-item:nth-child(4), .quantity{
    width: 12%;
    text-align: right;
    padding-right: 1rem;
}

.aoc-scroll-list li .contacttListItem-div:hover{
    background-color: var(--grey-100) !important;
}
.aoc-item-div{
    display:flex;
    box-shadow: none;
    
    border-bottom: 1px solid #00000024;
    padding: 1rem;
    margin: 1rem;
    background: white;
    justify-content: space-between;
    min-height:2rem;
    border-radius: 4px;
}
/* 
.contacttListItem-divMobile{
    display:flex;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid #00000024;
} */
.contacttListItem-div {
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; */
    /* align-content: center; */
    /* width: 100%; */
    /* background: #ffffff; */
    /* height: 100%; */
    /* box-shadow: 0px 2px 8px #00000024;
    border-radius: 4px;
    height: 4.5rem;
    margin: 1rem;
    padding: 0 1rem; */
}


@media only screen and (max-width: 1200px) {
    .aoc-list-container{
        display: block;
    /* height: 7.5rem; */
    padding: 0.7rem;
    border-bottom: 1px solid #00000024;

        /* height: 7.5rem; */
    }
    /* .contacttListItem-div {
       display: block;
       padding-left: 5px;
    } */
    .pres-name-mobile{
        /* width:65%; */
        padding-left: 5px;
    }
    /* .product-name-mobile{

    } */
    .status-mobile{
        width: 25%;
    }
    .quantity-mobile{
        width: 10%;
    }
    .aoc-container{
        background-color: white !important;
        /* height: 100vh; */
    }
    .pres-name-mobile label{
        font-weight: bold;
    }
    .aoc-list-wrapper{
        height: calc(100vh - 70px - 53px);
        border-top: 1px solid #ccc;
        overflow: auto;
    }
}
