#presList div {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    padding: 0.5rem;
    font-weight: 00;
    color: #929292;
}
.associateInput{
 background-color: var(--white);
 border-radius: 100vw !important;
 color: var(--dark--grey) !important;
}

.associateInput .css-1v4qvbo-MuiFormLabel-root-MuiInputLabel-root {
    transform: translate(14px, -10px);
    background: var(--bg--purple) !important;

}
.associateInput input:focus{
    border-radius: 100vw !important;
    /* box-shadow: 0 2px 2px rgb(0 0 0 / 16%); */
}

.associateInput {
border: 1px solid #d3d3d370 !important;
}

.inputDisable {
    background: var(--bg--purple) !important;
    color: var(--primary-text-color)!important;
    border-radius: 100vw !important;
}

#messageBox-label {
    color: var(--primary-color) !important;
    z-index: 1;
    display: block !important;
    transform: translate(14px, -9px) scale(0.75);
    position: absolute !important;
    font-weight: bold !important;
}