

.calendar-header{
    display: flex;
    height: 2%;
    align-items: center;
    padding: 2rem;
    border: 2px solid #dcd8d840;
    line-height: 60px;
}
.calendar-body{
    height: 90%;
    overflow-y: scroll;
}

.syncAni{
    animation-name: syncani; 
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }
  .Badge{
    background-color:var(--tertiary-color)
  }
  
  .fc-theme-standard .fc-popover {
    border: 1px solid #ddd;
    border: 1px solid var(--fc-border-color, #ddd);
    background: #fff;
    height: 11rem;
    overflow-y: scroll;
    background: var(--fc-page-bg-color, #fff);
  }
  
  @keyframes syncani {
    from{transform: rotate(0deg);}
    to {transform: rotate(360deg);  }
  }
  
  .fc-event {
    background-color: var(--primary-color)!important;
    flex-wrap:wrap;
   }
  
  .Syncbtn {
  
    background-color: var(--primary-color);
  
    border: none;
  
    border-radius: 7px;
  
    color: white;
  
    padding: 12px 16px;
  
    font-size: 16px;
  
    cursor: pointer;
  
  }
  
  .Sync:active {
  
    opacity: 0.8;
  
  }
@media only screen and (min-width: 1200px) and (hover: hover) and (any-pointer: fine){
    .font-md {
        font-size: 1rem !important;
    }
    .calendar-container{
        display: flex;
        width: auto;
        height: auto;
        flex-direction: column;
        flex-grow: inherit;
        background: var(--secondary-color) !important;;
    }
   
    
    
}

.css-heg063-MuiTabs-flexContainer {
    height: 10vh;
}

@media only screen and (min-width: 1200px) and (hover: hover) and (any-pointer: fine){
    .fc .fc-scrollgrid-liquid{
        height: 42% !important;
    }
    .fc-scrollgrid-sync-table{
        height: 0px !important;
    }
    .calendarTabPanel {
        overflow: hidden !important;
    }
  /* tbody{
    height: 28rem !important;
  } */
  .fc-event {
    background-color: var(--primary-color)!important;
}
.fc-event-time{
    color:var(--white-color) !important;
}
.fc-event-title{
    color:var(--white-color) !important;
}
.MuiTab-textColorPrimary {
    font-weight: 600 !important;
    text-transform: capitalize !important;
    font-size: 1rem !important;
    color: #595959 !important;;
    padding: 1rem 6rem !important;
  
}
.MuiTabs-flexContainer .Mui-selected {
    color: black !important;
    border-bottom: 5px solid var(--primary-color) !important;
}
.fc-col-header {
    background-color: var(--light-grey-200);
}
.calendar-container{
    font-size: 1rem;
}
.fc-timeGridWeek-button{
    padding: 0 18px !important;
    border-radius: 5px !important;
}
.fc-dayGridMonth-button, .fc-prev-button {
    margin-right: 10px !important;
}

.fc .fc-button-group .fc-button-active, .fc .fc-button-active {
    background-color: var(--tertiary-color) !important;
    color: var(--white-color) !important;
  }
.fc-dayGridWeek-button, .fc-dayGridMonth-button {
    background: #00000029 !important;
    border-radius: 5px !important;
    border: none !important;
    color: black !important;
    text-transform: capitalize !important;
}
.fc-dayGridWeek-button, .fc-dayGridMonth-button {
    height: 40px !important;
    width: 80px !important;
    font-size: 14px !important;
}

.fc .fc-button-primary {
    color: black;
    /* color: var(--fc-button-text-color, #fff); */
    background-color: #00000029;
    background-color: var(--fc-button-bg-color, #2C3E50);
    border-color: #2C3E50;
    border-color: var(--fc-button-border-color, #2C3E50);
}
.fc-next-button, .fc-prev-button {
    background: #00000029 !important;
    border: none !important;
}
.fc .fc-button-group .fc-timeGridWeek-button{
    background-color: #00000029;
    color:black;
    border: none;
    text-transform: capitalize !important;
}
.fc-next-button span, .fc-prev-button span {
    color: var(--primary-color);
}
.fc-scrollgrid-sync-inner .fc-col-header-cell-cushion,.fc-daygrid-day-top .fc-daygrid-day-number {
   color:#595959
}
 .fc-daygrid-day  {
    height: 20px !important;
 }
 .fc .fc-toolbar-title {
    font-size: 1.2rem !important;
    font-weight: bold;
    /* color:var(--secondary-color); */
    color: #595959;
 }
 .Sync{
    margin:1.5rem !important;
}
.css-13xfq8m-MuiTabPanel-root{
    padding: 24px 24px 0px 24px !important;
}
}

@media only screen and (max-width: 1200px){
    .MuiTab-textColorPrimary  {
        font-weight: 600 !important;
        text-transform: capitalize !important;
        font-size: 1rem !important;
        color: #595959 !important;
        width:10rem;
    }
    .fc-event {
        background-color: var(--primary-color)!important;
        flex-wrap:wrap;
    }
    .fc-event-time {
        color: var(--white-color) !important;
    }
    .fc-event-title {
        color: var(--white-color) !important;
    }
    .fc-h-event .fc-event-main-frame {
        flex-wrap: wrap;
    }
    .Sync{
        margin-top: 2.3rem  !important;
    }
    .fc .fc-button-primary {
        color: black;
        /* color: var(--fc-button-text-color, #fff); */
        background-color: #00000029 !important;
        border: none !important;
    }
    .fc .fc-button {
        height: 36px !important;
        /* margin: 0px 0; */
        padding: 0;
        width: 55px !important;
        font-size: 14px !important;
    }
    .calendar-container{
        background-color: none;
    }
    .css-heg063-MuiTabs-flexContainer {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        /* display: flex; */
        /* margin-left: 25px; */
        /* align-items: center; */
    }
    .calendarTablist .Mui-selected {
        color: black !important;
        border-bottom: 5px solid var(--primary-color) !important;
        width: 10rem;
    }
    .calendar-container{
        font-size: 1rem;
    }
    .fc .fc-button-group .fc-timeGridWeek-button{
        background-color: #00000029;
        color:black;
        border: none;
        text-transform: capitalize !important;
    }
    .fc .fc-button-group .fc-button-active, .fc .fc-button-active {
        background-color: var(--tertiary-color) !important;
        color: var(--white-color) !important;
      }
      .fc-dayGridWeek-button, .fc-dayGridMonth-button {
        height: 35px !important;
        width: 56px !important;
        font-size: 14px !important;
    }
    .fc-dayGridMonth-button, .fc-prev-button {
        margin-right: 7px !important;
    }
    .fc-dayGridWeek-button, .fc-dayGridMonth-button {
        background: #00000029 !important;
        border-radius: 5px !important;
        border: none !important;
        color: black !important;
        text-transform: capitalize !important;
    }
    .css-13xfq8m-MuiTabPanel-root {
        /* overflow: hidden !important; */
        padding: 7px  !important;
    }
    .fc-timeGridWeek-button{
        padding: 0 9px !important;
        border-radius: 5px !important;
    }
    .fc .fc-toolbar-title {
        font-size: 1rem !important;
        margin: 0;
        font-weight: bold !important;
    }
    .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion,.fc-daygrid-day-top .fc-daygrid-day-number {
        color:#595959
     }
     .fc-next-button span, .fc-prev-button span {
        color: var(--primary-color);
    }
}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse){
    .MuiTab-textColorPrimary  {
        font-weight: 600 !important;
        text-transform: capitalize !important;
        font-size: 1rem !important;
        color: #595959 !important;
        width:10rem;
    }
    .fc-event {
        background-color: var(--primary-color)!important;
    }
    .fc-event-time {
        color: var(--white-color) !important;
    }
    .fc-event-title {
        color: var(--white-color) !important;
    }
    .Sync{
        margin-top: 2.3rem  !important;
    }
    .fc .fc-button-primary {
        color: black;
        /* color: var(--fc-button-text-color, #fff); */
        background-color: #00000029 !important;
        border: none !important;
    }
    .fc .fc-button {
        height: 36px !important;
        /* margin: 0px 0; */
        padding: 0;
        width: 55px !important;
    }
    .calendar-container{
        background-color: none;
    }
    .css-heg063-MuiTabs-flexContainer {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        /* display: flex; */
        /* margin-left: 25px; */
        /* align-items: center; */
    }
    .calendarTablist .Mui-selected {
        color: black !important;
        border-bottom: 5px solid var(--primary-color) !important;
        width: 10rem;
    }
    .calendar-container{
        font-size: 1rem;
    }
    .fc .fc-button-group .fc-timeGridWeek-button{
        background-color: #00000029;
        color:black;
        border: none;
        text-transform: capitalize !important;
    }
    .fc .fc-button-group .fc-button-active, .fc .fc-button-active {
        background-color: var(--tertiary-color) !important;
        color: var(--white-color) !important;
      }
      .fc-dayGridWeek-button, .fc-dayGridMonth-button {
        height: 35px !important;
        width: 56px !important;
        font-size: 14px !important;
    }
    .fc-dayGridMonth-button, .fc-prev-button {
        margin-right: 7px !important;
    }
    .fc-dayGridWeek-button, .fc-dayGridMonth-button {
        background: #00000029 !important;
        border-radius: 5px !important;
        border: none !important;
        color: black !important;
        text-transform: capitalize !important;
    }
    .css-13xfq8m-MuiTabPanel-root {
        /* overflow: hidden !important; */
        padding: 7px  !important;
    }
    .fc-timeGridWeek-button{
        padding: 0 9px !important;
        border-radius: 5px !important;
    }
    .fc .fc-toolbar-title {
        font-size: 1rem !important;
        margin: 0;
        font-weight: bold !important;
    }
    .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion,.fc-daygrid-day-top .fc-daygrid-day-number {
        color:#595959
     }
     .fc-next-button span, .fc-prev-button span {
        color: var(--primary-color);
    }
}
@media only screen and (max-width: 600px){
    .calendarTablist .Mui-selected {
        color: black !important;
        border-bottom: 5px solid var(--primary-color) !important;
        /* width: 6rem; */
    }
    .Sync{
        margin-top: 2rem  !important;
    }
}

 .css-1h9z7r5-MuiButtonBase-root-MuiTab-root { 
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    max-width: 360px;
    min-width: 90px;
    position: relative;
    min-height: 48px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 12px 16px;
    overflow: hidden;
    white-space: normal;
    text-align: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.6);
} 
@media (min-width: 768px) and (max-width: 1024px) {
    .Sync {
        margin-top: 2.5rem !important;
    }
}