.errordiv{
    align-items: center;
    justify-content: center;
    text-align: center;
    display:flex;
    width: 100%;
    height:100%;
    color:#b5b5b5
}
.error h1{
    color: var(--primary-color);
    font-weight: bold;
}
.error img{
    
    width: 30%;
    height: 30%;
}