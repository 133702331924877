.avatar-container{
    border-radius: 50%;
    /* margin-right: 1rem; */
    /* width: 4rem;
    height: 3.5rem; */
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    color: var(--primary);
    overflow: hidden;
    background-color: #faf8ff;
    /* border: 1px solid rgba(115,75,209,.301961);   */
}
.avatar-container svg, .avatar-container img{
    width: 100%;
    fill: var(--primary)
}
.other-staff svg, .other-staff img{
    width: 100%;
    height: 100%;
}
.unknown-icon-width, .emp-user img{
    width: 58% !important;
}
.avatar-border{
    border: 2px solid rgba(115,75,209,.301961)
}
 .MuiAvatar-circular{
    /* background-color: rgb(from var(--primary-color)) r g b / 0.5 !important; */
      font-size: 1rem;
      font-weight: bold;      
      border: 1px solid #734BD14D
}
.MuiAvatar-fallback{
    display: none !important;
}