
@media (min-width:320px) and  (max-width:820px) {
.vchat-main{
    display: block;
    margin: 0.5rem 0rem !important;
    overflow-y: scroll;
    width: 98%;
    position: fixed;
    height: 80%;
    overflow: auto;
   }

   .vchat-details{
    display: flex;
    font-size: 14px;
    font-weight: 500;
    color: #595959;
    align-items: center;
}
.items-view-main{
    margin-bottom: 100px;
}
.svg-icons{
    margin-left: 0.5rem;
    color:var(--primary-color)
}
.chat-content{
    margin:0.2rem;
    display: inline-block;
    font-size: 14px;
}
.chat-main{
    margin-top: 0.5rem;
    box-shadow: 0px 2px 8px #00000024;
    border-radius: 4px;
    height: 7rem;
    width: 20rem;
    padding: 0.5rem 1rem;
    margin-left:0.5rem;
}
.avatar-logo{
    margin-bottom: 4rem;
}
.timestamp{
    display: inline-block;
    margin-left: 2rem;
    font-size: smaller;
}
}

.items-view-main{
    margin-bottom: 100px;
}
.vchat-main{
    display: block;
    margin-left: 2rem;
    margin-bottom: 1rem;
    width: 98%;
    height: 80%;
   }

   .vchat-details{
    display: flex;
    font-size: 14px;
    font-weight: 500;
    color: #595959;
    align-items: center;
}
.svg-icons{
    margin-left: 0.5rem;
    color:var(--primary-color)
}
.chat-content{
    margin:0.2rem;
    display: inline-block;
    font-size: 14px;
}
.chat-main{
    margin-top: 0.5rem;
    box-shadow: 0px 2px 8px #00000024;
    border-radius: 4px;
    height: 7rem;
    width: 20rem;
    padding: 0.5rem 1rem;
    margin-left:0.5rem;
}
.avatar-logo{
    margin-bottom: 4rem;
}
.timestamp{
    display: inline-block;
    margin-left: 2rem;
    font-size: smaller;
}

.validation{
    margin: auto;
    width: 14%;
    /* border: 3px solid green; */
    padding: 10px;
    font-size: 1rem;
   }

   .chat-validation{
    margin: auto;
    width: 85%;
    display: flex;
    /* border: 3px solid green; */
    padding: 13px;
    font-size: 1rem;
    justify-content: center;
   }