.TreeView ul{
    width: 100% !important;
}

.minusIcon{
    width: 0.6rem;
    height: 0.6rem;
    background-color: var(--light-grey-700);
    /* var(--primary-color); */
}

.plusIcon{
    width: 0.6rem;
    height: 0.6rem;
    border: 1px solid var(--light-grey--400);
}


.treeViewItem ul{
    position: relative;
    border-left: 1px solid;
    /* border-top: 1px solid; */ 
    padding-left: 1rem;
}
.treeViewItem {
    margin: 0.3rem;
}
/* .treeViewItem .mask{
    width: 2rem;
    left: 0%;
    top: 2rem;
    height: 1px;
    background: #070707;
    position: absolute;
} */

.treeViewItem .MuiTreeItem-label{
    padding: 0.2rem;
    background-color: white;
}

.treeViewItem .Mui-selected{
    background-color: #ffffff00 !important;
}

/* .treeViewItem .mask{
    position: absolute;
    width: 3rem;
    height: 1px;
    background: #9b5f0e;
    left: 0;
} */


  .wtree{
    width: 40rem;
    padding: 1rem;
    position: relative;
  }
  .wtree li{
    list-style-type: none;
    margin: 0px 0 0px 2rem;
    position: relative;
    border-left: 1px solid var(--light-grey--400);

  }
  
  /* .wtree li:last-child:after {
    display: none;
  } */
  .wtree li span {
    display: flex;
    color: var(--secondary-text-color);
    width: 100%;
    height: 100%;
    background-color: var(--white-color);
    box-shadow: 0px 2px 5px #0000001A;
    text-decoration: none;
    margin-bottom: 5px;
    border: none;
    font-size: 1.099rem;
    padding: 5px;

  }
  .itemInside{
    margin-left: 1.9rem;
  }
  .itemInside a{
    text-decoration: none;
    color: var(--secondary-text-color);
    background-color: var(--white-color);
    display: block;
    width: 100%;
    margin-bottom: 5px;
    box-shadow: 0px 2px 5px #0000001A;
    padding: 5px 0px 5px 2px
  }
  .itemInside a:hover{
    color: var(--primary-color);
    background-color: var(--grey-100);
  }
  .campaign-icon{
    width: 1rem;
    margin: 0rem 0.3rem;

  }
  .campaign-icon svg{
    fill: var(--dark--grey--500);
    width: 100%;
    height: 100%;
  }
  /* .wtree li span::before
        {
            content: "-";
        }
        .wtree li span::before
        {
            content: "-";
        } */

  /* .wtree li span:hover, .wtree li span:focus {
    background: #eee;
    color: #000;
    border: 1px solid #aaa;
  }
  .wtree li span:hover + ul li span, .wtree li span:focus + ul li span {
    background: #eee;
    color: #000;
    border: 1px solid #aaa;
  }
  .wtree li span:hover + ul li:after, .wtree li span:focus + ul li:after, .wtree li span:hover + ul li:before, .wtree li span:focus + ul li:before {
    border-color: #aaa;
  } */

  .wtree li:before{
    content: "";
    /* position: absolute;
    top: -10px;
    left: -20px;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd; */
    /* width: 20px; */
    /* height: 26px; */
  }
  .wtree li:after{
    position: absolute;
    content: "";
    /* top: 16px;
    left: -20px;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    width: 20px;
    height: 100%; */
  }

  .insideIconSpan{
    align-items: center;
  }
  .iconSpanDiv span{
    margin-left: 0.3rem;
  }
  .iconSpanDiv::after{
    content: '';

  }
  .iconSpanDiv::before{
    content: '';

    
  }
  .maskBorder{
    border-bottom: 1px solid var(--light-grey--400);
    
    height: 50%;
    width: 1rem;
  }

 
