.assets-container {
    display: flex;
    width: auto;
    height: auto;
    flex-direction: column;
    flex-grow: inherit;
    background: var(--secondary-color) !important;
    ;
}

.assets-header-mobile {
    border: 2px solid #dcd8d840;
    height: 0;
}

.assets-header {
    display: flex;
    /* height: 2%; */
    align-items: center;
    /* padding: 1rem 0rem 1rem 2rem; */
    padding: 1rem;
    border: 2px solid #dcd8d840;
}

.assetdesc {
    display: inline-block;
    position: relative;
}

#dropdown-assets {
    height: 4rem;
}

#dropdown-assets-height-0 {
    height: 0;
}

.assets-header .fullwidth {
    width: 100%;
}

.assets-body {
    /* height: 90%; */
    overflow-y: scroll;
}


ul {
    margin: 0;
    width: 100%;

    cursor: pointer;
    list-style: none;
}

.assets-body>ul>li {
    text-decoration: none;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    padding-bottom: 1.5rem;
}

.assetsListItem-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* align-content: center; */
    /* width: 100%; */
    background: #ffffff;
    /* height: 100%; */
    box-shadow: 0px 2px 8px #00000024;
    border-radius: 4px;
    height: 4.5rem;
    padding: 0.5rem 1rem;
}

.avatar-div {
    display: flex;
    padding: 1rem;
    align-items: center;
}

.list-title {
    display: flex;
    justify-content: space-between;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1.099rem;
    font-weight: bold;
    text-align: center;
    color: var(--primary-color);
    align-items: center;
    padding-top: 1rem;
    position: sticky;
    top: 0;
    background: var(--secondary-color) !important;
    z-index: 1;
}

select {
    width: 20rem;
    border: none;
    border-radius: 100vw;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    color: gray;
}


.flex-basis-15 {
    flex-basis: 15%;
}

.icon-2 {
    font-size: 0.899rem;
}


.filterDropdownAssets {
    max-width: 20rem;
}

.filterDropdownAssets .genericDropdownDiv {
    min-width: 20rem;
}

.assetListViewButtonsContainer {
    flex: 100%;
    justify-content: flex-end;
}

/* for Mobile */
@media only screen and (max-width: 1200px) {

    .assets-container {

        background: white !important;
        ;
    }

    .assets-body>ul>li {
        padding: 0;
    }

    .assetsListItem-div {
        height: auto;
        box-shadow: none;
        border-bottom: 1px solid lightgray;
        justify-content: left;
    }

    .assetsListItem-div .avt-5 {
        height: 2rem;
        width: 2rem;
    }

    .filterDropdownAssets {
        width: 100%;
        max-width: 100%;
    }

    .multi-select-itemlist {
        white-space: break-spaces !important;
        padding: 0.2rem 0.7rem 0.2rem 0.3rem !important;
    }


}

@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse) {

    .assets-container {

        background: white !important;
        ;
    }

    .assets-body>ul>li {
        padding: 0;
    }

    .assetsListItem-div {
        height: auto;
        box-shadow: none;
        border-bottom: 1px solid lightgray;
        justify-content: left;
    }

    .assetsListItem-div .avt-5 {
        height: 2rem;
        width: 2rem;
    }

    .filterDropdownAssets {
        width: 100%;
        max-width: 100%;
    }

    /* .filterDropdownAssets .genericDropdownDiv{
        min-width: 100%;
      } */

}

.whatsapp {
    border: none !important;
    background: none !important;
    color: var(--primary-color) !important;
}


.asset-list-ul {
    padding-bottom: 1.2rem;
}