.FormURLHeader{
    margin-left: 7%;
}
/* .Forms-header{
    display: flex; */
    /* height: 2%; */
    /* justify-content: center;
    align-items: center;
    padding: 1rem 0rem 1rem 2rem;
    border: 2px solid #dcd8d840;

} */
.FormName{
    width:18%!important;
}
.Status{
    width:11%;
    font-size: 1.099rem;
}
.Forms-header >.formcontent__header__searchbox {
    display: flex;
    width: 75% !important;
   
}
.Form-Nav{
    display: flex;
    /* height: 2%; */
    justify-content: center;
    align-items: center;
    padding: 0rem 0rem 0rem 2rem;
    border: 2px solid #dcd8d840;
}
.FormUL{
    display: flex;
}
/* li:active{
    
} */
.FormList{
    border-bottom: 3px solid;
    padding: 2rem 0 1rem;
    color: var(--primary-color);
    font-size: 1.099rem;
}
.contacts-container .row--three>.formcontent__header__searchbox{
    background-color: #fff!important;
    border-radius: 100vw;
    margin: 10px 30rem 10px 0;
    /* margin-left: 35%; */
}
.formcontent__header__searchbox{
    margin-top: 0.5rem;
}
.search-form{
    border-radius: 2rem;
    border: none;
}
#createFormId{
    margin-left: auto;
    margin-right: 30px;
    margin-top: 13px;
}
.FormNameHeader{
    margin-left: 1%;
    width: 15%;
}
.FormDateHeader{
    margin-left: 8%;
}
.form-body>ul>li{
    text-decoration: none;
}
.form-body{
    overflow-y: auto;
}
.formcontent__header__searchbox i{
    color:var(--secondary-color);
}
.formcontent__header__searchbox input:focus{
    box-shadow: none;
    border: none;
}
.edit{
    margin: 0 1rem;
    color: var(--primary-color);
}
.link{
    color:var(--primary-color);
}
.delete{
    margin: 0 1rem;
    color: var(--primary-color);
}
.view{
    color: var(--primary-color);
}
.edit:hover{
    color: var(--primary-color);
}
.delete:hover{
    color: var(--primary-color);
}
.FormTypeHeader{
    /* margin-left: 13%; */
}
.FormStatusHeader{
    margin-left: 31%;
}
.FormtListItem-div {
    display: flex;
    /* flex-direction: row; */
    /* justify-content: space-between; */
    align-items: center;
    /* align-content: center; */
    /* width: 100%; */
    background: #ffffff;
    /* height: 100%; */
    box-shadow: 0px 2px 8px #00000024;
    border-radius: 4px;
    height: 4.5rem;
    margin: 1rem;
    /* padding: 0 1rem; */

}
.formlist-title{
    display: flex;
    /* justify-content: space-between; */
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    font-size: 1.099rem;
    font-weight: bold;
    text-align: start !important;
    color: var(--primary-color);
    align-items: center;
    padding-top: 1rem;
    position: sticky;
    top: 0;
    background: #F5F5F5 !important;
    z-index: 1;
}
.MobilePage{
    left: 0;
    line-height: 200px;
    margin-top: -100px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
    font-size: large;
}
.form-request-list-title{
    font-size: medium;
    font-weight: bold;
    text-align: start !important;
    color: var(--primary-color);
    align-items: center;
    padding-top: 1rem;
    position: sticky;
    padding: 1rem 1rem;
    
}
.form-request-list-item{
    padding: 1.5rem 0;
    background: white;
    margin: 1rem 0;
    font-size: large;
}
