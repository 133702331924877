@media (min-width:320px) and  (max-width:820px) {
    .vfiles-main{
        display: none;
    }

    .only-mobile-view{
        display: block;
        overflow-y: scroll;
        width: 98%;
        position: fixed;
        height: 80%;
        overflow: auto;
    }

    .items-view-main{
        margin-bottom: 100px;
    }

    .file-icon{
        transform: scale(1.6);
    }

    .participants{
        display: flex;
        flex-direction: column;
    }

    .file-logo{
        margin: 0.5rem;
    }

    .past-files-details{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 1rem;
        padding:0.5rem;
        box-shadow: 0px 1px 0px #0000001A;
    }

    .past-files-detail-item{
        gap: 0.3rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    p{
        margin: 0rem !important;
    }

    .past-files-detail-items{
        padding: 0rem;
        flex-grow: 1;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        gap:1rem;
    }


    .past-files-length{
        padding: 1rem;
        font-size: 1rem;
    }
    .past-files-name{
        flex: 1;
        font-size: 1.1rem;
        line-height: 1.1rem;
        font-weight: bold;
        color: #595959;
    }

    .past-files-date{
        font-size: 0.8rem;
        color: #828287;
    }

    .past-files-duration{
        font-size: 1rem;
        color: #734BD1;
    }

    .past-files-time{
        font-size: 0.8rem;
        color: #828287;
    }
   
}

@media (min-width:320px) and  (max-width:768px) {
.vfiles-main{
    display: block;
    margin-left: 0rem;
    border-top: 2px solid #dcd8d840;
    border-bottom: 2px solid #dcd8d840;
   }

   /* .onlyMobile{
        display: block;
        overflow-y: scroll;
        width: 98%;
        position: fixed;
        height: 80%;
        overflow: auto;
    } */

    .items-view-main{
        margin-bottom: 100px;
    }
    
   .video-files-headers{
    display: flex;
   }
   .vchat-details{
    display: flex;
    font-size: 14px;
    font-weight: 500;
    color: #595959;
    align-items: center;
}
.svg-icons{
    margin-left: 0.5rem;
    color:var(--primary-color)
}
.chat-content{
    margin:0.2rem;
    display: inline-block;
    font-size: 14px;
}
.chat-main{
    margin-top: 0.5rem;
    box-shadow: 0px 2px 8px #00000024;
    border-radius: 4px;
    height: 7rem;
    width: 20rem;
    padding: 0.5rem 1rem;
    margin-left:0.5rem;
}
.avatar-logo{
    margin-bottom: 4rem;
}
.timestamp{
    display: inline-block;
    margin-left: 2rem;
    font-size: smaller;
}

.video-file-list-item{
    display: flex;
    padding: 1rem 0rem;
    font-size: 17px;
        font-weight: bold;
        color: #595959;
        margin-left: 10rem;
}

.video-type-list-item{
    display: flex;
    padding: 1rem 0rem;
    font-size: 17px;
        font-weight: bold;
        color: #595959;
        margin-left: 2rem;
}
.file-content{
    display: flex;
    padding:1rem 2rem 0.2rem 2rem;
    position: relative;
}
.videofile-details{
    margin: 0.1rem 5rem 1rem 11rem;
    font-size: 17px;
}
.videofileicons{
    /* font-size: rem !important; */
    color:var(--primary-color);
   }
   .file-popup{
    margin-top: 1rem;
    /* margin: 1rem; */
    justify-content: space-between;
    align-items: center;
    /* align-content: center; */
    width: 15%;
    background: #ffffff;
    position: relative;
    top: 40%;
    /* left: 35%; */
    /* height: 100%; */
    box-shadow: 0px 2px 8px #00000024;
    border-radius: 4px;
    height: 4.5rem;
    padding: 1rem 1rem 2.5rem 1rem;
   }
   .videopopup-details{
    margin:0.1rem 1rem;
    font-size: 14px;
   }
   .videopopup-row{
    display: flex;
    padding-bottom: 0.5rem;
   }
 
}

.validation{
    margin: auto;
    width: 14%;
    /* border: 3px solid green; */
    padding: 10px;
    font-size: 1rem;
   }

   .mob-validation{
    margin: auto;
    width: 100%;
    display: flex;
    /* border: 3px solid green; */
    padding: 13px;
    font-size: 1rem;
    justify-content: center;
   }

   .disabled_options{
    opacity: 0.3;
   }