.home-container{
    display: grid;
    grid-template-columns: 8% 92%;
    grid-template-rows: 100vh;
    justify-content: center;
    align-content: center;
    overflow: hidden;
}

.main-content-div{
    display: grid;
    grid-template-rows: 10% 90%;
}

.bn-tab {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newmobileSideBarIcons{
  color: #ffffff;
  font-size: 1.5rem;
}

.newmobileSideBarIcons img{
  user-drag: none;  
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.menu-item-text{
  color: #ffffff !important;
  font-size: 10px;
}

.activepage{
  font-size: 12px;
    color: rgb(255, 255, 255);
    font-weight: 800;
    letter-spacing: 1px;
    text-shadow: 1px 1px black;
}

.profileactive{
  font-size: 12px;
    color: rgb(255, 255, 255);
    font-weight: 800;
    letter-spacing: 1px;
    text-shadow: 1px 1px black;
}
.intial-loader{
  background-color: #F5F5F5;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/* .init-page-loader {
  border: 18px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid blue;
  border-bottom: 16px solid blue;
  width: 120px !important;
  height: 120px !important;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} */

.init-page-loader {
  width: 100px;
  height: 20px;
  padding-top: 20px;
  /* border: 1px solid black; */
  position: relative;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%, -50%);
}
.init-page-loader > div {
  content: " ";
  width: 20px;
  height: 20px;
  background: #2196F3;
  border-radius: 100%;
  position: absolute;
  animation: shift 2s linear infinite;
}
.init-page-loader > div:nth-of-type(1) {
  animation-delay: -0.4s;
}
.init-page-loader > div:nth-of-type(2) {
  animation-delay: -0.8s;
}
.init-page-loader > div:nth-of-type(3) {
  animation-delay: -1.2s;
}
.init-page-loader > div:nth-of-type(4) {
  animation-delay: -1.6s;
}
@keyframes shift {
  0% {
    left: -60px;
    opacity: 0;
    background-color: yellow;
  }
  10% {
    left: 0;
    opacity: 1;
  }
  90% {
    left: 100px;
    opacity: 1;
  }
  100% {
    left: 160px;
    background-color: red;
    opacity: 0;
  }
}


@media only screen and (max-width: 1024px) { 
.footerMob{
  display: flex !important;
    justify-content: center
}
.main-content-div{
  grid-template-columns: 100vw;
}
}
@media only screen and (min-width: 1024px) {
  .footerMob{
    display: none;
  }
  }
/* for mobile */
@media only screen and (max-width: 1200px) {
  
    .home-container{
        display: block;
    }
    
    .notMobile{

        display: none !important;
      }
      .onlyMobile{
        display: block;
      }
      
      .main-content-div{
       
        grid-template-rows: auto;
    }
    #main{
      padding-top: env(safe-area-inset-top);
      padding-bottom: env(safe-area-inset-bottom);
      padding-left: env(safe-area-inset-left);
      padding-right: env(safe-area-inset-right);
    }
  }
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse) {
  
    .home-container{
        display: block;
    }
   
    .notMobile{

        display: none !important;
      }
      .onlyMobile{
        display: block;
      }
      
      .main-content-div{
       
        grid-template-rows: auto;
    }
    #main{
      padding-top: env(safe-area-inset-top);
      padding-bottom: env(safe-area-inset-bottom);
      padding-left: env(safe-area-inset-left);
      padding-right: env(safe-area-inset-right);
    }
  }


  @media only screen and (min-width: 1200px) {

    #Videotimer{
      position: absolute;
      right: 50%;
      top:2rem;
      font-size: small; 
      border-radius: 0.5rem;
      background: red;
      color: white;
      padding: 0.5rem;
    }
}
@media only screen and (max-width: 1200px){

  #Videotimer{
    position: absolute;
    margin: auto;
    /* left: 0; */
    right: 0;
    text-align: center;
    width: 4rem;
    font-size: small;
    border-radius: 0.5rem;
    background: red;
    color: white;
    top: 2rem;
  }
}